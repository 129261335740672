.projects-list-item {
    width: 100%;
    height: 100%;
    perspective: 1500px;
    transition: transform .1s;
}

.projects-list-item {
    align-self: stretch;
    justify-self: stretch;
}

.projects-list-item-link.lift {
    text-decoration: none;
}

.projects-list-item-link:hover {
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, .3);
}

.hidden {
    display: none;
}

.projects-list-item-wrap {
    position: relative;
    z-index: 2;
    width: 100%;
}

.projects-list-item-wrap.fsm {
    overflow: auto;
    border-radius: 22px;
    transition: .5s;
}

.project-list-item-container {
    position: relative;
    z-index: 5;
    overflow: hidden;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 22px;
    background-color: #f7f7f7;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    transform: scale3d(1 none, 1 none, 1 none) translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
}

.form-checkbox-field, .form-field, .project-list-item-container {
    background-color: var(--form-field);
}

.project-item-close {
    display: flex;
    opacity: 0;
    position: absolute;
    left: auto;
    top: 16px;
    right: 16px;
    bottom: auto;
    z-index: 3;
    width: 32px;
    height: 32px;
    padding: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(29, 29, 31, .8);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .12);
    -webkit-transition: -webkit-transform .5s;
    transition: transform .5s;
    color: #afafb5;
}


.projects-list-item:hover .project-item-close {
    animation: fadeIn .1s ease-in;
    opacity: 1;
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.opened .project-item-close {
    opacity: 1;
}

.w-embed::after, .w-embed::before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.project-item-close-icon {
    display: flex;
    justify-content: center;
    align-items: center;
}

.project-list-img-wrap {
    position: relative;
    width: 100%;
    padding-top: 52.04%;
}

.project-item-vid {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity .2s;
}

.projects-list-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: none;
    object-fit: cover;
}


.project-item-info-wrap {
    display: none;
}

.project-info-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 560px;
    margin-right: auto;
    margin-bottom: 56px;
    margin-left: auto;
    padding-top: 64px;
    padding-right: 18px;
    padding-left: 18px;
    flex-direction: column;
    align-items: center;
    text-align: center;
}


.project-services-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 12px;
    padding: 6px 16px;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    background-color: hsla(0, 0%, 46%, .12);
}

.project-service-label {
    border-color: currentColor;
}

.project-service-label {
    opacity: .65;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.025em;
}

.w-condition-invisible, .w-dyn-bind-empty, .w-dyn-hide {
    display: none !important;
}

.project-services-dot {
    width: 4px;
    height: 4px;
    min-width: 4px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 50%;
    opacity: .65;
}

.project-services-dot {
    background-color: currentColor;
}

.project-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 64px;
    line-height: 1.2;
    letter-spacing: -.045em;
    font-weight: 600;
}

.project-info-cta-wrap {
    position: relative;
    z-index: 2;
    display: flex;
    padding-bottom: 56px;
    justify-content: center;
    align-items: center;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.project-cta {
    padding: 10px 28px;
    align-items: center;
    border-radius: 12px;
    background-color: #006ddb;
    transition: background-color .2s;
    color: #fff;
    font-weight: 500;
    text-decoration: none;
}

.swiper-container {
    overflow: hidden;
    width: 100%;
    border-top: 1px solid transparent;
    background-color: #fff;
}

[data-theme="dark"] .swiper-container {
    background-color: #1d1d1f;
    border-color: rgba(136, 136, 136, 0.18);
}

.project-slider-img {
    display: block;
}

.w-dyn-empty {
    padding: 10px;
    background-color: #ddd;
}

.w-condition-invisible, .w-dyn-bind-empty, .w-dyn-hide {
    display: none !important;
}

.projects-list-item-wrap.fsm {
    overflow: auto;
    border-radius: 22px;
    transition: .5s;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.projects-list-item-wrap::-webkit-scrollbar {
    display: none;
}

.form-checkbox-field, .form-field, .project-list-item-container {
    background-color: var(--form-field);
}

.rotate-btn {
    transform: rotate(45deg);
}

.full-opacity {
    opacity: 1;
}

.display-block {
    display: block;
}

@media only screen and (max-width: 991px) {
    .opened {
        border-radius: 0 !important;
    }
}

@media screen and (min-width: 991px) {
    .lift {
        transition-property: box-shadow, transform;
        transition-duration: 100ms;
        transition-timing-function: linear;
    }

    .projects-list-item-link {
        position: relative;
        z-index: 2;
        display: block;
        overflow: hidden;
        width: 100%;
        border: 1px solid hsla(0, 0%, 62%, .2);
        border-radius: 22px;
        transition: box-shadow .5s;
        transition-property: box-shadow;
        transition-duration: 0.5s;
        transition-timing-function: ease;
        color: #000;
    }
}

@media screen and (max-width: 767px) {
    .projects-list-item {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0;
    }
}

@media screen and (max-width: 479px) {
    .projects-list-item {
        margin-top: 5vw;
    }

    .project-services-wrap {
        padding: 5px 12px;
    }

    .project-title {
        font-size: 48px;
    }
}