.intro-card-performance-img {
    width: 120%;
    max-width: 600px;
    margin-top: 36px;
    margin-bottom: -26px;
    margin-left: -64px;
    -webkit-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 0% 50%;
    object-position: 0% 50%;
}