.intro-card-dev-speed-wrap {
    display: flex;
    margin-top: 32px;
    flex-direction: column;
    align-items: center;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -.045em;
    transform: translate3d(0px, 25%, 0px)
}

.intro-card-dev-speed-months {
    position: relative;
}

.dev-speed-month-line {
    position: absolute;
    top: 50%;
    height: 10px;
    margin-left: -5%;
    border-radius: 20px;
    background-color: #1d1d1f;
}

.dev-speed-month-line {
    background-color: var(--text);
}

.dev-speed-weeks {
    background-image: linear-gradient(-70deg, #ed4e50, #9867f0);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    opacity: 0;
}

.reveal-in-bottom {
    transform-style: preserve-3d;
    animation: reveal-in-bottom 0.5s linear both;
    animation-delay: 750ms;
}

@keyframes reveal-in-bottom {
    0% {
        transform: translate3d(0px, 25%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    }
    100% {
        transform: translate3d(0px, 0%, 0px) scale3d(1, 1, 1) rotateX(0deg) rotateY(0deg) rotateZ(0deg) skew(0deg);
    }
}

.strikethrough {
    animation: strikethrough 0.4s ease-in both;
    animation-delay: 250ms;
}

@keyframes strikethrough {
    0% {
        width: 0;
    }
    100% {
        width: 110%;
    }
}

.reveal-opacity {
    animation: reveal-opacity 0.4s linear both;
    animation-delay: 850ms;
}

@keyframes reveal-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.hide-opacity {
    animation: hide-opacity 0.4s linear both;
    animation-delay: 850ms;
}

@keyframes hide-opacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: .15;
    }
}

@media screen and (max-width: 767px) {

}

@media screen and (max-width: 479px) {
    .intro-card-dev-speed-wrap {
        font-size: 56px;
    }
}
