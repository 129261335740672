.form-field-wrap {
    margin-bottom: 22px
}

.form-field-label {
    display: flex;
    margin-bottom: 8px;
    align-items: center;
    font-weight: 500;
    letter-spacing: -.025em
}

.form-field-area {
    display: block;
    width: 100%;
    padding: 8px 18px;
    line-height: 1.42857143;
    height: auto;
    max-width: 520px;
    min-height: 250px;
    margin-bottom: 0;
    border: 2px solid hsla(0, 0%, 53.3%, .2);
    border-radius: 14px;
    background-color: var(--form-field);
    color: var(--text);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.02em;
    cursor: none;
}

.form-field-area::placeholder {
    color: #999;
    opacity: 1;
}


.form-field-area:focus {
    border-color: #3898ec;
    outline: 0
}

.warning {
    border-color: #DA6161;
}

@media screen and (max-width: 991px) {
    .form-field-area {
        cursor: auto
    }
}

@media screen and (max-width: 767px) {
    .form-field-label {
        font-size: 14px
    }
}

@media screen and (max-width: 479px) {
    .form-field-area {
        padding-right: 16px;
        padding-left: 16px;
        border-radius: 12px
    }
}