.intro-card {
    position: relative;
    padding: 64px;
    border: 1px solid hsla(0, 0%, 51.4%, .16);
    border-radius: 28px;
    background-color: #fff
}

.cta-link, .load-more-button, .intro-card, .workflow-card, .faq-front, .faq-back {
    background-color: var(--card-bg);
}

.intro-card.intro-card--stats-cell {
    display: flex;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.intro-card.intro-grid-cell--animation {
    display: grid;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;
    border-style: none;
    background-color: #1d1d1f;
    background-image: linear-gradient(120deg, #eb4f56, #9d66e9);
    color: #fff;
}

.intro-grid-cell--animation {
    grid-row-start: span 1;
    grid-row-end: span 1;
    grid-column-start: span 2;
    grid-column-end: span 2;
}

.intro-card.intro-card--performance {
    overflow: hidden;
}

.intro-card.intro-card--integrations {
    display: flex;
    overflow: hidden;
    padding: 0 0 97px;
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    transform: translate(0, 0);
    text-align: center;
}

.intro-card.intro-card--pixel {
    display: flex;
    padding-right: 24px;
    padding-left: 24px;
    justify-content: center;
    align-items: center;
    border-style: none;
    background-color: #1d1d1f;
    background-image: url('../assets/img/6137431ff45a39b264299e9d_pixel-pattern.png'), linear-gradient(45deg, #16327c, #1ebf98);
    background-position: 50% 0, 0 0;
    background-size: cover, auto;
    background-repeat: no-repeat, repeat;
    color: #f5f5f7;
}

.intro-card.intro-card--responsive {
    display: flex;
    padding-top: 58px;
    padding-bottom: 0;
    align-items: flex-start;
    overflow: clip;
}

.intro-card.intro-card--responsive img{
    margin-left: -136px;
}

.intro-card.intro-card--tiny.cc-code {
    padding-right: 32px;
}

.intro-card.intro-card--tiny.cc-dev-speed {
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 991px) {
    .intro-grid-cell--animation, .intro-card.intro-grid-cell--animation, .intro-card.intro-card--tiny.cc-dev-speed {
        grid-row-start: span 1;
        grid-row-end: span 1;
        grid-column-start: span 1;
        grid-column-end: span 1
    }

    .intro-card.intro-grid-cell--animation {
        grid-row-gap: 32px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    .intro-card.intro-card--stats-cell {
        padding-top: 48px;
        padding-bottom: 48px;
    }
    .intro-card.intro-card--responsive {
        flex-direction: column;
    }

    .intro-card.intro-card--responsive img {
        min-height: 0;
        margin-top: 20px;
        margin-left: 0;
    }

}

@media screen and (max-width: 479px) {
    .intro-card {
        grid-row-start: span 1;
        grid-row-end: span 1;
        grid-column-start: span 1;
        grid-column-end: span 1
    }

    .intro-card {
        padding: 38px 28px;
        border-radius: 24px;
    }

    .intro-card.intro-grid-cell--animation {
        padding-bottom: 20px;
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }

    .intro-card.intro-card--integrations {
        padding-bottom: 38px;
    }

    .intro-card.intro-card--pixel {
        padding-top: 71px;
        padding-bottom: 71px;
    }

    .intro-card.intro-card--responsive {
        padding-top: 32px;
        padding-bottom: 18px;
    }

    .intro-card.intro-card--responsive img {
        max-width: 100%;
        margin-top: 28px;
    }

    .intro-card.intro-card--tiny {
        padding-top: 28px;
        padding-right: 28px;
        padding-left: 28px;
    }

    .intro-card.intro-card--tiny.cc-code {
        padding-right: 5px;
    }

    .intro-card.intro-card--tiny.cc-dev-speed {
        padding-top: 63px;
        padding-bottom: 48px;
    }
}
