.checkbox-input {
    opacity: 0;
    position: absolute;
    z-index: -1
}

.form-checkbox-field::before {
    content: ' ';
    display: table;
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1
}

.form-checkbox-field::after {
    content: ' ';
    display: table;
    grid-column-end: 2;
    grid-column-start: 1;
    grid-row-end: 2;
    grid-row-start: 1;
    clear: both
}

.form-checkbox-field {
    position: relative;
    display: flex;
    width: 100%;
    min-height: 64px;
    margin-bottom: 20px;
    padding: 16px 56px 16px 28px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 18px;
    background-color: var(--form-field);
}

.form-checkbox {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    border-width: 2px;
    border-color: hsla(0, 0%, 53.3%, .2);
    border-radius: 18px;
    background-image: url("../../assets/icons/checkbox.svg");
    background-position: 100% 50%;
    background-size: auto;
    background-repeat: no-repeat;
    border-style: solid;

}

.checkbox-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

.checkbox-checked {
    border: 2px solid #006ddb;
    border-radius: 18px;
    background-color: transparent;
    background-image: url(../../assets/icons/checkbox-checked.svg);
    background-position: 100% 50%;
    background-size: auto
}

.form-checkbox-title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.02em;
    display: inline-block;
    cursor: pointer;
}

@media screen and (max-width: 991px) {
    .form-checkbox-field {
        cursor: auto
    }

    .form-checkbox-field:hover {
        transform: none
    }
}

@media screen and (max-width: 479px) {
    .form-checkbox-title {
        font-size: 16px
    }
}