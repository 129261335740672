.section.section--manifest {
    padding-top: 100px;
    padding-bottom: 160px;
}

.section {
    padding-top: 140px;
    padding-bottom: 140px;
}

.section--gray {
    background-color: var(--secondary-bg);
}

@media screen and (max-width: 767px) {
    .section.section--manifest {
        padding-top: 100px;
        padding-bottom: 30px;
    }
    .section {
        padding-top: 80px;
        padding-bottom: 80px;
    }


}

@media screen and (max-width: 479px) {

}