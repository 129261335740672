.hero-scroll-wrap {
    position: sticky;
    height: 170vh;
    width: 100%;
}

.hero-scroll-trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: -1;
    height: 100vh;
}

.sticky-wrap {
    position: sticky;
    top: 0;
    z-index: 1;
}

.sticky-wrap.sticky-wrap--hero {
    height: 60vw;
    min-height: 100vh;
}

.overflow-wrap {
    overflow: hidden;
    height: 100%;
}

.hero {
    display: flex;
    height: 100vh;
    align-items: center;
}

.hero-ui {
    position: relative;
    left: 50%;
    z-index: 1;
    width: 16.9em;
    max-width: none;
    margin-top: 3.2em;
    margin-left: -8.45em;
    opacity: 0;
    font-size: 10vw;
}

.container {
    width: 90%;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
}

.container.container--hero {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: 1;
    display: flex;
    height: 100vh;
    max-width: none;
    padding-top: 100px;
    padding-bottom: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
}

.hero-img-wrap {
    position: relative;
    z-index: 2;
    width: 25vw;
    height: 25vw;
    max-height: 40vh;
    max-width: 40vh;
    min-height: 350px;
    min-width: 350px;
    margin-bottom: 64px;
}

.hero-img-wrap {
    will-change: opacity;
    animation: heroImgWrap 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.8s;
}

@keyframes heroImgWrap {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hero-img-container {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 12px;
    border: 1px solid hsla(0, 0%, 100%, .3);
    border-radius: 50%;
    background-color: hsla(0, 0%, 84%, .5);
    object-fit: cover;
}

.hero-img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 50%;
}

.hero-img {
    will-change: transform;
    animation: heroImgScale 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.8s;
}

@keyframes heroImgScale {
    from {
        transform: scale(2, 2);
    }

    to {
        transform: scale(1, 1);
    }
}


.hero-img-shadow-wrap {
    position: absolute;
    left: -20%;
    top: -20%;
    right: 0;
    bottom: 0;
    width: 140%;
    height: 140%;
    padding: 20%;
    border-radius: 50%;
    filter: blur(20px);
}

#hero-img-shadow-1 {
    will-change: opacity;
    animation: heroImgShadow1 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 1.5s;
}

@keyframes heroImgShadow1 {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.hero-img-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: radial-gradient(circle farthest-side at 100% 100%, #9873ff, rgba(152, 115, 255, 0)), radial-gradient(circle farthest-side at 100% 0, #0ba5f7, rgba(85, 196, 255, 0)), radial-gradient(circle farthest-side at 0 0, #ff763c, rgba(255, 176, 60, 0)), radial-gradient(circle farthest-side at 0 100%, #ff5aaa, rgba(255, 90, 170, 0));
}

.hero-img-shadow-wrap.hero-img-shadow-wrap--intro {
    z-index: 1;
}

#hero-img-shadow-2 {
    will-change: opacity, transform;
    animation: heroImgShadow2 1.2s cubic-bezier(0.65, 0, 0.35, 1);
    animation-iteration-count: 1;
    animation-fill-mode: both;
    animation-delay: 1.5s;
}

@keyframes heroImgShadow2 {
    0% {
        opacity: 1;
        transform: scale(1, 1);
    }

    99% {
        opacity: 0;
        transform: scale(6, 6);
        height: 140%;

    }

    100% {
        height: 0%;
        opacity: 0;
    }
}

.hero-img-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: radial-gradient(circle farthest-side at 100% 100%, #9873ff, rgba(152, 115, 255, 0)), radial-gradient(circle farthest-side at 100% 0, #0ba5f7, rgba(85, 196, 255, 0)), radial-gradient(circle farthest-side at 0 0, #ff763c, rgba(255, 176, 60, 0)), radial-gradient(circle farthest-side at 0 100%, #ff5aaa, rgba(255, 90, 170, 0));
}

.hero__header {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.hero-eyebrow {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: -.03em;
}

.hero-eyebrow {
    will-change: transform, opacity;
    animation: fadeIn 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.4s;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(100px);
    }

    to {
        opacity: auto;
        transform: translateY(0px);
    }
}

.hero-heading-ui-wrap {
    position: relative;
    margin-top: 18px;
    margin-bottom: 22px;
}

.hero-heading-ui {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border: 1px solid #168bf4;
    opacity: 0;
}

.hero-heading-ui-tab {
    position: absolute;
    left: -1px;
    top: -20px;
    right: auto;
    bottom: auto;
    display: flex;
    height: 20px;
    padding: 3px 12px 2px 8px;
    align-items: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #168bf4;
    color: #fff;
    font-size: 11px;
}

.hero-heading-ui-tab-h1 {
    margin-right: 4px;
}

.hero-heading span{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 92px;
    line-height: 1.05;
    letter-spacing: -.045em;
}

.hero-heading {
    will-change: transform, opacity;
    animation: fadeIn 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.5s;
}

.hero-paragraph-mobile {
    display: none;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 500;
    letter-spacing: -.03em;
}

.hero-paragraph-mobile {
    will-change: transform, opacity;
    animation: fadeIn 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.6s;
}

.w-inline-block {
    max-width: 100%;
    display: inline-block;
}

.cta-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #006ddb;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.025em;
    text-decoration: none;
    white-space: nowrap;
}

.cta-link--hero {
    will-change: transform, opacity;
    animation: fadeIn 0.8s ease;
    animation-iteration-count: 1;
    animation-fill-mode: backwards;
    animation-delay: 0.6s;
}

.cta-link, .load-more-button, .intro-card, .workflow-card, .faq-front, .faq-back {
    /*background-color: var(--bg);*/
}

.cta-link.cta-link--hero {
    position: relative;
    z-index: 10;
    padding: 10px 28px;
    font-size: 23px;
}

.cta-link-icon {
    display: flex;
    width: 11px;
    height: 10px;
    margin-left: 2px;
    padding-top: 2.5px;
    justify-content: center;
    align-items: center;
}

.cta-link-icon.cta-link-icon--hero {
    width: 13px;
    height: 12px;
    margin-top: 3.5px;
    margin-left: 4px;
    padding-top: 0;
}

.w-embed:after, .w-embed:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.w-embed:after {
    clear: both;
}

.sticky-gap {
    height: 100vh;
}

@media screen and (max-width: 767px) {
    .hero-img-wrap {
        min-height: 50vw;
        min-width: 50vw;
    }

    .hero-eyebrow {
        font-size: 20px;
    }

    .hero-heading span{
        font-size: 2.5rem;
    }

    .cta-link.cta-link--hero {
        margin-top: 4rem;
        font-size: 1.3rem;
    }

    .hero-scroll-wrap {
        position: relative;
        height: 90vh;
    }
}

@media screen and (max-width: 479px) {

}