.stats-figure-wrap {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.intro-stats-figure {
    display: inline;
    margin-left: -2px;
    font-size: 64px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -.05em;
}

.stats-figure-wrap.stats-figure-wrap--clients {
    background-image: linear-gradient(225deg, #5af141, #2a9dce);
}

.stats-figure-wrap.stats-figure-wrap--experience {
    background-image: linear-gradient(135deg, #63e3dc, #1254c2);
}

.stats-figure-wrap.stats-figure-wrap--projects {
    background-image: linear-gradient(225deg, #e374ff, #3d30ea);
}

.stats-figure-wrap.stats-figure-wrap--passion {
    background-image: linear-gradient(135deg, #ffb077, #b82698);
}

.intro-stats-symbol {
    display: inline;
    padding-left: 3px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -.025em;
}

.intro-stats-symbol--plus {
    vertical-align: top;
}

.intro-stats-symbol.intro-stats-symbol--plus {
    font-size: 32px;
    line-height: .6;
}

.intro-stats-symbol.intro-stats-symbol--text, .intro-stats-symbol.intro-stats-symbol--percent {
    align-self: flex-end;
}

.stats-description {
    margin-top: 4px;
    opacity: .75;
    font-weight: 500;
    letter-spacing: -.025em;
}

@media screen and (max-width: 767px) {
    .intro-stats-figure {
        font-size: 3rem;
    }

    .stats-description {
        margin-top: 2px;
        font-size: .9rem;
    }
}

@media screen and (max-width: 479px) {

}