// flexbox
@mixin flex($justify: flex-start, $align: flex-start, $flow: row wrap) {
  flex-flow: $flow;
  display: flex;
  justify-content: $justify;
  align-items: $align;
}

// breakpoints
$breakpoints: (
        "mobile": 375px,
        "mobile2x": 480px,
        "mobile3x": 600px,
        "tablet": 768px,
        "tablet2x": 992px,
  // 'tablet3x': 1024px,
        "desktop": 1200px,
        "desktop2x": 1440px,
        "desktop3x": 1680px,
) !default;

@mixin respondMin($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (min-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@mixin respondMax($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "+"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}

@keyframes infiniteArrow {
  0% {
    transform: translateY(-30%);
  }
  100% {
    transform: translateY(30%);
  }
}

@keyframes colorOne {
  0% {
    transform: rotate(0deg) translate(-40px) rotate(0deg) scale(1, 1);
  }

  50% {
    transform: rotate(-180deg) translate(-40px) rotate(-180deg) scale(1, 1.33);
  }

  100% {
    transform: rotate(-360deg) translate(-40px) rotate(-360deg) scale(1, 1);
  }
}

@keyframes colorThree {
  0% {
    transform: rotate(0deg) translate(30px) rotate(0deg) scale(1, 1);
    opacity: 1;
  }

  66% {
    transform: rotate(180deg) translate(30px) rotate(-180deg) scale(1, 1.33);
    opacity: 0.33;
  }

  100% {
    transform: rotate(360deg) translate(30px) rotate(-360deg) scale(1, 1);
    opacity: 1;
  }
}

@keyframes colorTwo {
  0% {
    transform: rotate(0deg) translate(30px) rotate(0deg) scale(1, 1);
  }

  33% {
    transform: rotate(180deg) translate(30px) rotate(-180deg) scale(1.66, 1);
  }

  100% {
    transform: rotate(360deg) translate(30px) rotate(-360deg) scale(1, 1);
  }
}

.cursorSmall {
  display: inline-block;
  position: fixed;
  z-index: 5;
  pointer-events: none;
  background: var(--col-dark);
  border-radius: 100%;
  width: 1.5rem;
  height: 1.5rem;
  mix-blend-mode: difference;
}

.cursor {
  pointer-events: none;
  position: fixed;
  right: 0;
  top: 0;
  z-index: -1;
  opacity: 0.85;
  div {
    position: fixed;
    z-index: -1;
    width: 50rem;
    height: 50rem;
    @include respondMax("mobile2x") {
      width: 40rem;
      height: 40rem;
    }

    &:nth-child(1) {
      top: calc(50% - 15rem);
      left: calc(50% - 15rem);
    }

    &:nth-child(2) {
      top: calc(37% - 22rem);
      left: calc(35% - 22rem);
    }

    &:nth-child(3) {
      top: calc(68% - 22rem);
      left: calc(45% - 22rem);
    }
  }
}

.colorOne {
  animation: 5s colorOne linear infinite;
  background-image: -webkit-radial-gradient(
                  50% 50%,
                  circle contain,
                  rgba(#ff873c, 1),
                  rgba(#ff873c, 0) 100%
  );
}

.colorTwo {
  animation: colorTwo 4s linear infinite;
  background-image: -webkit-radial-gradient(
                  50% 50%,
                  circle contain,
                  rgba(#c7003b, 1),
                  rgba(#c7003b, 0) 100%
  );
}

.colorThree {
  animation: colorThree 6s linear infinite;
  background-image: -webkit-radial-gradient(
                  50% 50%,
                  circle contain,
                  rgba(#900c3e, 1),
                  rgba(#900c3e, 0) 100%
  );
}

.slide-horizontal {
  &:hover {
    .char {
      &:before {
        transform: translateX(0%);
        transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
      }
      &:after {
        transform: translateX(-110%);
        transition-delay: calc(0.02s * (var(--char-index)));
      }
    }
  }
  .char {
    overflow: hidden;
    color: transparent;

    &:before,
    &:after {
      visibility: visible;
      color: var(--col-dark);
      transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
      transition-delay: calc(0.2s + (0.02s * (var(--char-index))));
    }

    &:before {
      color: var(--col-orange);
      transition-delay: calc(0.02s * (var(--char-index)));
      transform: translateX(110%);
    }
  }
}
