.section-header.section-header--intro {
    position: relative;
    max-width: 1050px;
    margin-bottom: 0;
}

.section-header {
    width: 90%;
    max-width: 980px;
    margin-right: auto;
    margin-bottom: 142px;
    margin-left: auto;
    text-align: left;
}

.fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
    font-size: 103px;
}

.fluid-gradient-heading.fluid-gradient-heading--hero {
    margin-right: auto;
    margin-left: auto;
    line-height: 1.05;
    text-align: center;
}

.fluid-gradient-heading {
    background: linear-gradient(91.36deg, #ECA658 0%, #F391A6 13.02%, #E188C3 25.52%, #A58DE3 37.5%, #56ABEC 49.48%, #737EB7 63.02%, #C8638C 72.92%, #DD5D57 84.38%, #DF6C51 97.92%);
    background-size: 200% 200%;
    background-clip: text;
    animation: intro-gradient 10s infinite ease both;
}

.fluid-gradient-heading {
    margin-top: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 92px;
    line-height: 1.1;
    letter-spacing: -.045em;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section-heading-eyebrow {
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: 0.1em;
    opacity: .75;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -.04em;
}

.section-heading-eyebrow {
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: 0.1em;
    opacity: .75;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -.04em;
}

.section-heading {
    max-width: 890px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 92px;
    line-height: 1.1;
    letter-spacing: -.045em;
}
@media screen and (max-width: 991px) {
    .section-heading {
        max-width: 650px;
        font-size: 72px;
    }
}

@media screen and (max-width: 767px) {
    .fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
        font-size: 3rem;
    }

    .section-heading {
        font-size: 4rem;
    }

    .section-header {
        margin-bottom: 80px;
    }

    .section-heading-eyebrow {
        padding-left: .1em;
        font-size: 24px;
    }

    .section-heading {
        font-size: 56px;
    }
}

@media screen and (max-width: 479px) {
    .section-heading-eyebrow {
        margin-bottom: 12px;
        font-size: 22px;
    }

    .section-heading {
        font-size: 52px;
    }
}
