.form-intro {
    display: flex;
    height: 100%;
    overflow: auto;
    padding: 32px;
    align-items: center;
    border-radius: 24px;
    background-color: var( --form-bg)
}

.form-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 520px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.form-intro-img-wrap {
    position: relative;
    margin-bottom: 42px
}

.hero-img-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: radial-gradient(circle farthest-side at 100% 100%, #9873ff, rgba(152, 115, 255, 0)), radial-gradient(circle farthest-side at 100% 0, #0ba5f7, rgba(85, 196, 255, 0)), radial-gradient(circle farthest-side at 0 0, #ff763c, rgba(255, 176, 60, 0)), radial-gradient(circle farthest-side at 0 100%, #ff5aaa, rgba(255, 90, 170, 0))
}

.form-intro-img {
    width: 150px;
    height: 150px;
    margin: 4px;
    border-radius: 50%
}

.form-heading {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -.025em
}

.form-intro-text {
    opacity: .7;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -.015em
}

.form-start-button {
    position: relative;
    margin-top: 36px
}

.form-intro-bottom {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 16px;
    padding-right: 18px;
    padding-left: 18px;
    opacity: .5;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.015em
}

.cta-link {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #006ddb;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.025em;
    text-decoration: none;
    white-space: nowrap
}

.cta-link--form {
    position: relative;
    z-index: 203;
    padding: 10px 28px;
    font-size: 21px;
    max-width: 100%;
}

.cta-link-icon {
    display: flex;
    width: 11px;
    height: 10px;
    margin-left: 2px;
    padding-top: 2.5px;
    justify-content: center;
    align-items: center
}

.cta-link-icon--hero {
    width: 13px;
    height: 12px;
    margin-top: 3.5px;
    margin-left: 4px;
    padding-top: 0
}

@media screen and (max-width: 991px) {
    .form-heading {
        font-size: 34px
    }
}

@media screen and (max-width: 767px) {
    .form-intro-img {
        width: 120px;
        height: 120px
    }

    .form-heading {
        font-size: 28px
    }

    .form-start-button {
        margin-top: 32px
    }

    .cta-link {
        font-size: 16px
    }

    .cta-link--form {
        font-size: 18px
    }
}

@media screen and (max-width: 479px) {
    .form-header {
        max-width: 250px
    }

    .form-intro  {
        border-radius: 0
    }
}
