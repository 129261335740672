.intro-card-heading {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -.025em;
}

.intro-card-heading.intro-cell-heading--big {
    font-size: 44px;
}

.intro-card-description {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 0;
    opacity: .75;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: -.025em;
}

.intro-card-description.intro-card-description--animations {
    max-width: 390px;
}

.anim-video-wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 500px;
    justify-content: center;
    align-items: center;
}

.anim-video-wrap{
    justify-self: center;
}

.anim-video-container {
    position: relative;
    width: 100%;
}


.anim-video-display {
    position: relative;
    z-index: 2;
    width: 100%;
}

.w-embed::after, .w-embed::before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2;
}

.w-background-video {
    position: relative;
    overflow: hidden;
    height: 500px;
    color: #fff;
}

.anim-video {
    position: absolute;
    left: 1%;
    top: 1.4%;
    z-index: 1;
    width: 98%;
    height: 73.5%;
    flex: 1;
}

.w-background-video > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100;
}

@media screen and (max-width: 767px) {
    .intro-card-description {
        margin-top: 12px;
    }
}

@media screen and (max-width: 479px) {
    .intro-card-heading, .intro-card-heading.intro-cell-heading--big {
        font-size: 28px;
    }

    .intro-card-description.intro-card-description--animations {
        margin-top: 12px;
    }
}