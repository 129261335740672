.intro-card-heading {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -.025em;
}

.intro-card-heading.intro-cell-heading--big {
    font-size: 44px;
}

.intro-card-heading.intro-card-heading--tiny {
    font-size: 18px;
}

.intro-card-heading.intro-card-heading--tiny.cc-dev-speed {
    position: absolute;
    left: 52px;
    top: 64px;
    right: auto;
    bottom: auto;
}

@media screen and (max-width: 767px) {
    .intro-card-heading, .intro-card-heading.intro-cell-heading--big {
        font-size: 32px;
    }
}

@media screen and (max-width: 479px) {
    .intro-card-heading, .intro-card-heading.intro-cell-heading--big {
        font-size: 28px;
    }
    .intro-card-heading.intro-card-heading--tiny {
        font-size: 16px;
    }

    .intro-card-heading.intro-card-heading--tiny.cc-dev-speed {
        left: 28px;
        top: 32px;
    }
}