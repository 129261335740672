.projects-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.projects-list-wrapper {
    width: 100%;
    flex: 1;
}

.projects-list {
    position: relative;
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr;
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
}

.project-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 505;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    opacity: 0;
}

.settings-handle, .language-notice-wrap, .project-overlay {
    backdrop-filter: blur(30px);
}