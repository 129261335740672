.form-field-wrap {
    margin-bottom: 22px
}

.form-field-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: -.025em
}

.form-field-label--optional {
    margin-left: 4px;
    opacity: .7;
    font-size: 12px;
    font-weight: 500
}

.form-field-text {
    display: block;
    height: 48px;
    margin-bottom: 0;
    width: 100%;
    padding: 8px 18px;
    line-height: 1.42857143;
    border: 2px solid hsla(0, 0%, 53.3%, .2);
    border-radius: 14px;
    background-color: var(--form-field);
    color: var(--text);
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.02em;
    cursor: none
}

.form-field-text::placeholder {
    color: #999;
    opacity: 1;
}


.form-field-text:focus {
    border-color: #3898ec;
    outline: 0
}

.warning {
    border-color: #DA6161;
}

@media screen and (max-width: 991px) {
    .form-field-text {
        cursor: auto
    }
}

@media screen and (max-width: 767px) {
    .form-field-label {
        font-size: 14px
    }
}

@media screen and (max-width: 479px) {
    .form-field-text {
        padding-right: 16px;
        padding-left: 16px;
        border-radius: 12px
    }
}