/*.anfrage-overlay-wrap {*/
/*    position: fixed;*/
/*    left: 0;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    z-index: 200;*/
/*    display: none;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    -webkit-box-pack: center;*/
/*    -webkit-justify-content: center;*/
/*    -ms-flex-pack: center;*/
/*    justify-content: center;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    grid-auto-columns: 1fr;*/
/*    -ms-grid-columns: 1fr 1fr;*/
/*    grid-template-columns:1fr 1fr;*/
/*    -ms-grid-rows: auto auto;*/
/*    grid-template-rows:auto auto;*/
/*    background-color: #ddd*/
/*}*/

/*.anfrage-wrap {*/
/*    position: relative;*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    overflow: hidden;*/
/*    width: 90%;*/
/*    height: 90vh;*/
/*    max-height: 720px;*/
/*    max-width: 980px;*/
/*    -webkit-box-orient: vertical;*/
/*    -webkit-box-direction: normal;*/
/*    -webkit-flex-direction: column;*/
/*    -ms-flex-direction: column;*/
/*    flex-direction: column;*/
/*    border-radius: 28px;*/
/*    background-color: #fff*/
/*}*/

/*.form-slider {*/
/*    position: static;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    background-color: transparent*/
/*}*/

/*.mask {*/
/*    position: static;*/
/*    height: 100%*/
/*}*/

/*.form-slide {*/
/*    height: 100%;*/
/*    padding-bottom: 32px*/
/*}*/

/*.anfrage-bottom {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: auto;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    z-index: 2;*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    padding-top: 20px;*/
/*    padding-bottom: 20px;*/
/*    -webkit-box-pack: center;*/
/*    -webkit-justify-content: center;*/
/*    -ms-flex-pack: center;*/
/*    justify-content: center;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    background-color: #fff*/
/*}*/

/*.form-header {*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    max-width: 520px;*/
/*    margin-right: auto;*/
/*    margin-left: auto;*/
/*    -webkit-box-orient: vertical;*/
/*    -webkit-box-direction: normal;*/
/*    -webkit-flex-direction: column;*/
/*    -ms-flex-direction: column;*/
/*    flex-direction: column;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    text-align: center*/
/*}*/

/*.form-heading {*/
/*    margin-top: 0;*/
/*    margin-bottom: 16px;*/
/*    font-size: 40px;*/
/*    line-height: 1.05;*/
/*    font-weight: 600;*/
/*    letter-spacing: -.025em;*/
/*    white-space: normal;*/
/*}*/

/*.form-intro-img {*/
/*    width: 150px;*/
/*    height: 150px;*/
/*    margin: 4px;*/
/*    border-radius: 50%*/
/*}*/

/*.anfrage-form-block {*/
/*    z-index: 1;*/
/*    overflow: hidden;*/
/*    margin-bottom: 0;*/
/*    padding-bottom: 74px;*/
/*    -webkit-box-flex: 1;*/
/*    -webkit-flex: 1;*/
/*    -ms-flex: 1;*/
/*    flex: 1*/
/*}*/

/*.anfrage-form {*/
/*    height: 100%*/
/*}*/

/*.form-eyebrow {*/
/*    margin-bottom: 8px;*/
/*    opacity: .7;*/
/*    font-weight: 600;*/
/*    letter-spacing: -.01em*/
/*}*/

/*.form-checkbox-wrap {*/
/*    margin-top: 32px*/
/*}*/

/*.form-checkbox-field {*/
/*    position: relative;*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    width: 100%;*/
/*    min-height: 64px;*/
/*    margin-bottom: 20px;*/
/*    padding: 16px 56px 16px 28px;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    border-radius: 18px;*/
/*    background-color: #f5f5f7;*/
/*    cursor: none*/
/*}*/

/*.form-checkbox-title {*/
/*    margin-bottom: 0;*/
/*    font-size: 18px;*/
/*    font-weight: 500;*/
/*    letter-spacing: -.02em*/
/*}*/

/*.form-checkbox {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    z-index: 1;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    margin-top: 0;*/
/*    margin-left: 0;*/
/*    border-width: 2px;*/
/*    border-color: hsla(0, 0%, 53.3%, .2);*/
/*    border-radius: 18px;*/
/*    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/605739cf94ca6b11afae090e_check%20initial.svg");*/
/*    background-position: 100% 50%;*/
/*    background-size: auto;*/
/*    background-repeat: no-repeat*/
/*}*/

/*.form-checkbox.w--redirected-checked {*/
/*    border: 2px solid #006ddb;*/
/*    border-radius: 18px;*/
/*    background-color: transparent;*/
/*    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/605739cf94ca6b0b55ae090f_check%20checked.svg");*/
/*    background-position: 100% 50%;*/
/*    background-size: auto*/
/*}*/

/*.anfrage-intro {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    z-index: 202;*/
/*    display: none;*/
/*    overflow: auto;*/
/*    padding: 32px;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    border-radius: 24px;*/
/*    background-color: #fff*/
/*}*/

/*.anfrage-start-button {*/
/*    position: relative;*/
/*    margin-top: 36px*/
/*}*/

/*.anfrage-nav-button-wrap {*/
/*    position: absolute;*/
/*    left: auto;*/
/*    top: 15px;*/
/*    right: 22px;*/
/*    bottom: auto;*/
/*    z-index: 203;*/
/*    font-weight: 500*/
/*}*/

/*.anfrage-nav-button-wrap.anfrage__abbruch--success {*/
/*    position: relative;*/
/*    left: auto;*/
/*    top: auto;*/
/*    right: 0;*/
/*    padding: 0*/
/*}*/

/*.anfrage-nav-button-wrap.anfrage-nav-button-wrap--back {*/
/*    left: 22px;*/
/*    top: 15px;*/
/*    right: auto;*/
/*    bottom: auto;*/
/*    opacity: 0*/
/*}*/

/*.form-content {*/
/*    padding-top: 33px*/
/*}*/

/*.form-field-wrap {*/
/*    margin-bottom: 22px*/
/*}*/

/*.form-field {*/
/*    height: 48px;*/
/*    margin-bottom: 0;*/
/*    padding-right: 18px;*/
/*    padding-left: 18px;*/
/*    border: 2px solid hsla(0, 0%, 53.3%, .2);*/
/*    border-radius: 14px;*/
/*    background-color: #f5f5f7;*/
/*    color: #1d1d1f;*/
/*    font-size: 16px;*/
/*    font-weight: 500;*/
/*    letter-spacing: -.02em;*/
/*    cursor: none*/
/*}*/

/*.form-field:focus {*/
/*    border-width: 2px;*/
/*    border-color: #006ddb*/
/*}*/

/*.form-field.form__field--area {*/
/*    height: auto;*/
/*    max-width: 520px;*/
/*    min-height: 250px*/
/*}*/

/*.form-field-label {*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    margin-bottom: 8px;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    font-weight: 500;*/
/*    letter-spacing: -.025em*/
/*}*/

/*.anfrage__datenschutz-check {*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    font-size: 12px;*/
/*    letter-spacing: -.015em;*/
/*    cursor: none*/
/*}*/

/*.link {*/
/*    color: #006ddb;*/
/*    letter-spacing: -.2px;*/
/*    text-transform: none*/
/*}*/

/*.anfrage__datenschutz-checkbox {*/
/*    width: 14px;*/
/*    height: 14px;*/
/*    min-width: 14px;*/
/*    margin-top: 2px;*/
/*    margin-right: 8px;*/
/*    border-color: #cecece;*/
/*    border-radius: 4px;*/
/*    background-color: #fafafa;*/
/*    box-shadow: inset 1px 1px 0 -.5px #fff, 1px 1px 2px -2px rgba(0, 0, 0, .1);*/
/*    cursor: none*/
/*}*/

/*.anfrage__datenschutz-checkbox.w--redirected-checked {*/
/*    border-color: #006ddb;*/
/*    border-radius: 4px;*/
/*    background-color: #006ddb;*/
/*    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/605739cf94ca6b32bdae0874_check-filled-2.svg");*/
/*    box-shadow: 1px 1px 2px -2px rgba(0, 0, 0, .1)*/
/*}*/

/*.anfrage-form__success {*/
/*    position: absolute;*/
/*    left: 0;*/
/*    top: 0;*/
/*    right: 0;*/
/*    bottom: 0;*/
/*    background-color: #fff*/
/*}*/

/*.anfrage__success-trigger {*/
/*    width: 0;*/
/*    height: 0*/
/*}*/

/*.anfrage-form__submit {*/
/*    display: none*/
/*}*/

/*.footer-menu-wrap {*/
/*    display: -webkit-box;*/
/*    display: -webkit-flex;*/
/*    display: -ms-flexbox;*/
/*    display: flex;*/
/*    padding-top: 18px;*/
/*    padding-bottom: 18px;*/
/*    -webkit-box-pack: justify;*/
/*    -webkit-justify-content: space-between;*/
/*    -ms-flex-pack: justify;*/
/*    justify-content: space-between;*/
/*    -webkit-box-align: center;*/
/*    -webkit-align-items: center;*/
/*    -ms-flex-align: center;*/
/*    align-items: center;*/
/*    font-size: 13px;*/
/*    font-weight: 500;*/
/*    letter-spacing: -.011em*/
/*}*/

/*.footer__link-wrap {*/
/*    margin-right: -12px*/
/*}*/

/*.footer-link {*/
/*    display: inline-block;*/
/*    padding: 6px 12px;*/
/*    opacity: .7;*/
/*    text-decoration: none*/
/*}*/

/*.footer-link:hover {*/
/*    opacity: 1*/
/*}*/

/*.footer-cta-wrap {*/
/*    display: block;*/
/*    padding-top: 240px;*/
/*    padding-bottom: 240px;*/
/*    color: #1d1d1f;*/
/*    text-decoration: none*/
/*}*/

/*.cta-link,*/
/*.load-more-button,*/
/*.workflow-card-step {*/
/*    color: var(--blue);*/
/*}*/

/*.intro-card,*/
/*.workflow-card,*/
/*.faq-front,*/
/*.faq-back {*/
/*    background-color: var(--bg);*/
/*}*/

/*.basics-list-item {*/
/*    border-color: var(--text);*/
/*}*/

/*.form-field {*/
/*    color: var(--text);*/
/*}*/

/*.anfrage-wrap,*/
/*.anfrage-intro,*/
/*.anfrage-bottom,*/
/*.form-slide-top,*/
/*.anfrage-form__success {*/
/*    background-color: var(--form-bg);*/
/*}*/

/*.form-checkbox-field,*/
/*.form-field,*/
/*.project-list-item-container {*/
/*    background-color: var(--form-field);*/
/*}*/

/*[data-theme="dark"] .anfrage-overlay-wrap {*/
/*    background-color: rgba(29, 29, 31, 0.94);*/
/*    !*background-color: #020202;*!*/
/*    !*background-image: radial-gradient(circle farthest-side at 100% 100%, #9873ff73, rgba(152, 115, 255, 0)),*!*/
/*    !*                  radial-gradient(circle farthest-side at 100% 0, #0ba5f77d, rgba(85, 196, 255, 0)),*!*/
/*    !*                  radial-gradient(circle farthest-side at 0 0, #ff763c78, rgba(255, 176, 60, 0)),*!*/
/*    !*                  radial-gradient(circle farthest-side at 0 100%, #ff5aaa8f, rgba(255, 90, 170, 0));*!*/
/*}*/


/*.slide-in-bottom {*/
/*    animation: slide-in-bottom 0.2s ease-in both;*/
/*}*/


/*@keyframes slide-in-bottom {*/
/*    0% {*/
/*        transform:  translate3d(0px, 50px, 0px);*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        transform:  translate3d(0, 0, 0);*/
/*        opacity: 1;*/
/*    }*/
/*}*/

/*.slide-out-bottom {*/
/*    animation: slide-out-bottom 0.2s linear both;*/
/*}*/


/*@keyframes slide-out-bottom {*/
/*    0% {*/

/*        transform:  translate3d(0, 0, 0);*/
/*        opacity: 1;*/
/*    }*/
/*    100% {*/
/*        transform:  translate3d(0px, 50px, 0px);*/
/*        opacity: 0;*/
/*    }*/
/*}*/

/*.slide-out-bottom-2 {*/
/*    animation: slide-out-bottom-2 0.2s linear both;*/
/*    display: none;*/
/*}*/

/*@keyframes slide-out-bottom-2 {*/
/*    0% {*/
/*        transform:  translate3d(0, 0, 0);*/
/*        opacity: 1;*/
/*    }*/
/*    100% {*/
/*        transform:  translate3d(0px, 50px, 0px);*/
/*        opacity: 0;*/
/*    }*/
/*}*/

/*.slide-in-bottom-2 {*/
/*    animation: slide-in-bottom-2 0.2s linear both;*/
/*    display: flex;*/
/*}*/

/*@keyframes slide-in-bottom-2 {*/
/*    0% {*/
/*        transform:  translate3d(0px, 50px, 0px);*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        transform:  translate3d(0, 0, 0);*/
/*        opacity: 1;*/
/*    }*/
/*}*/


/*.slide-in-bottom__lite {*/
/*    animation: slide-in-bottom__lite 0.1s linear both;*/
/*}*/


/*@keyframes slide-in-bottom__lite {*/
/*    0% {*/
/*        transform:  translate3d(0px, 30px, 0px);*/
/*        opacity: 0;*/
/*    }*/
/*    100% {*/
/*        transform:  translate3d(0, 0, 0);*/
/*        opacity: 1;*/
/*    }*/
/*}*/