.next-button {
    padding: 12px 90px;
    border-radius: 12px;
    background-color: #006ddb;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.015em;
    text-decoration: none
}

.next-button:hover {
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, .1))
}

.next-button:active {
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .15), rgba(0, 0, 0, .15))
}

@media screen and (max-width: 479px) {
    .next-button {
        width: 100%;
        text-align: center
    }
}