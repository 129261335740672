.from-btn-wrapper {
    display: flex;
    align-items: center;
    padding: 8px 12px;
}

.form-nav-button {
    display: flex;
    align-items: center;
    font-weight: 500;
    background-color: transparent;
    padding: 0 10px;
}

@media screen and (max-width: 991px) {
    .form-nav-button {
        cursor: pointer
    }
}

@media screen and (max-width: 479px) {
    .form-nav-button {
        font-size: 14px
    }
}