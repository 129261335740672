.intro-card-code-wrap {
    max-width: 540px;
    margin-top: 52px;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Ibm plex mono', sans-serif;
    font-size: 24px;
    line-height: 2;
    font-weight: 500;
}

.intro-card-code-indented {
    margin-left: 28px;
}

.code-color {
    color: #e62615;
}

@media screen and (max-width: 767px) {
    .intro-card-code-wrap {
        font-size: 2.8vw;
    }
}

@media screen and (max-width: 479px) {
    .intro-card-code-wrap {
        margin-top: 30px;
        margin-right: -12px;
        font-size: 3.3vw;
    }

    .intro-card-code-indented {
        margin-left: 3vw;
    }
}