.form-overlay-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ddd
}

[data-theme="dark"] .form-overlay-wrap {
    background-color: #1d1d1f;
}

.form-wrap {
    position: relative;
    display: flex;
    overflow: hidden;
    width: 90%;
    height: 90vh;
    max-height: 720px;
    max-width: 980px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 28px;
    background-color: var( --form-bg)
}

.form-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid hsla(0, 0%, 53.3%, .2);
    padding: 15px 22px;
    align-items: center;
}

.contact-form-block {
    z-index: 1;
    overflow: hidden;
    margin: 0 0 15px;
    padding-bottom: 74px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.contact-form {
    height: 100%
}

.form-footer {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    justify-content: center;
    align-items: center;
    background-color: var(--form-bg)
}

.disabled > a{
    background-color: #353535;
    color: #d9d9d9;
}

.disabled > a :hover {
    background-image: linear-gradient(180deg, #353535, #353535) !important;
}

.disabled > a :active {
    background-image: linear-gradient(180deg, #353535, #353535) !important;
}

@media screen and (max-width: 767px) {
    .form-wrap {
        max-height: none
    }

    .contact-form-block {
        padding-bottom: 56px
    }

    .form-footer {
        padding-top: 16px;
        padding-bottom: 16px
    }
}

@media screen and (max-width: 479px) {
    .form-footer {
        padding: 24px 18px
    }
}

.slide-in-bottom {
    animation: slide-in-bottom 0.2s ease-in both;
}

