.container {
    width: 90%;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
}

.container.container--large {
    width: 100%;
    max-width: 1440px;
    padding-right: 28px;
    padding-left: 28px;
}

@media screen and (max-width: 767px) {
    .container.container--large {
        width: 90%;
        padding-right: 0;
        padding-left: 0;
    }
}

@media screen and (max-width: 479px) {

}