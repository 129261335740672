.form-consent-label {
    font-weight: 500;
    margin-bottom: 5px;
    padding-left: 20px;
    display: flex;
    font-size: 12px;
    letter-spacing: -.015em;
    cursor: none;
}

.form-custom-checkbox {
    width: 14px;
    min-width: 14px;
    float: left;
    margin: 2px 8px 0 -20px;
    line-height: normal;
    border: 1px solid #cecece;
    border-radius: 4px;
    height: 14px;
    background-color: #fafafa;
    box-shadow: none;
    font-size: 12px;
    letter-spacing: -.015em;
    /*cursor: none;*/
}

.form-consent-label input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    padding: 0;
}

.form-consent-label span {
    display: inline-block;
    font-weight: 400;
    margin-bottom: 0;
}

.form-consent-label a {
    color: inherit;
}
