body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    height: 100%
}

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
    display: block
}

audio, canvas, progress, video {
    display: inline-block;
    vertical-align: baseline
}

audio:not([controls]) {
    display: none;
    height: 0
}

[hidden], template {
    display: none
}

a {
    background-color: transparent;
    text-decoration: underline;
    cursor: none
}

a:active, a:hover {
    outline: 0
}

abbr[title] {
    border-bottom: 1px dotted
}

b, optgroup, strong {
    font-weight: 700
}

dfn {
    font-style: italic
}

h1 {
    margin: .67em 0
}

mark {
    background: #ff0;
    color: #000
}

small {
    font-size: 80%
}

sub, sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline
}

sup {
    top: -.5em
}

sub {
    bottom: -.25em
}

img {
    border: 0;
    max-width: 100%;
    vertical-align: middle;
    display: inline-block
}

svg:not(:root) {
    overflow: hidden
}

hr {
    box-sizing: content-box;
    height: 0
}

pre, textarea {
    overflow: auto
}

code, kbd, pre, samp {
    font-family: monospace, monospace;
    font-size: 1em
}

button, input, optgroup, select, textarea {
    color: inherit;
    font: inherit;
    margin: 0
}

button {
    overflow: visible
}

button, select {
    text-transform: none
}

button[disabled], html input[disabled] {
    cursor: default
}

button::-moz-focus-inner, input::-moz-focus-inner {
    border: 0;
    padding: 0
}

input {
    line-height: normal
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box;
    padding: 0
}

input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button {
    height: auto
}

input[type=search] {
    -webkit-appearance: none
}

input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

legend {
    border: 0;
    padding: 0
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

td, th {
    padding: 0
}


[class*=" w-icon-"], [class^=w-icon-] {
    font-family: webflow-icons !important;
    speak: none;
    font-style: normal;
    font-weight: 400;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

.w-icon-slider-right:before {
    content: "\e600"
}

.w-icon-slider-left:before {
    content: "\e601"
}

.w-icon-nav-menu:before {
    content: "\e602"
}

.w-icon-arrow-down:before, .w-icon-dropdown-toggle:before {
    content: "\e603"
}

.w-icon-file-upload-remove:before {
    content: "\e900"
}

.w-icon-file-upload-icon:before {
    content: "\e903"
}

* {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box
}

html.w-mod-touch * {
    background-attachment: scroll !important
}

.w-block {
    display: block
}

.w-inline-block {
    max-width: 100%;
    display: inline-block
}

.w-clearfix:after, .w-clearfix:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-clearfix:after {
    clear: both
}

.w-hidden {
    display: none
}

.w-button {
    display: inline-block;
    padding: 9px 15px;
    background-color: #3898ec;
    color: #fff;
    border: 0;
    line-height: inherit;
    text-decoration: none;
    cursor: pointer;
    border-radius: 0
}

input.w-button {
    -webkit-appearance: button
}

html[data-w-dynpage] [data-w-cloak] {
    color: transparent !important
}

.w-webflow-badge, .w-webflow-badge * {
    position: static;
    left: auto;
    top: auto;
    right: auto;
    bottom: auto;
    z-index: auto;
    display: block;
    visibility: visible;
    overflow: visible;
    overflow-x: visible;
    overflow-y: visible;
    box-sizing: border-box;
    width: auto;
    height: auto;
    max-height: none;
    max-width: none;
    min-height: 0;
    min-width: 0;
    margin: 0;
    padding: 0;
    float: none;
    clear: none;
    border: 0 transparent;
    border-radius: 0;
    background: 0 0;
    box-shadow: none;
    opacity: 1;
    transform: none;
    transition: none;
    direction: ltr;
    font-family: inherit;
    font-weight: inherit;
    color: inherit;
    font-size: inherit;
    line-height: inherit;
    font-style: inherit;
    font-variant: inherit;
    text-align: inherit;
    letter-spacing: inherit;
    text-decoration: inherit;
    text-indent: 0;
    text-transform: inherit;
    list-style-type: disc;
    text-shadow: none;
    font-smoothing: auto;
    vertical-align: baseline;
    cursor: inherit;
    white-space: inherit;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal
}

.w-webflow-badge {
    position: fixed !important;
    display: inline-block !important;
    visibility: visible !important;
    z-index: 2147483647 !important;
    top: auto !important;
    right: 12px !important;
    bottom: 12px !important;
    left: auto !important;
    color: #aaadb0 !important;
    background-color: #fff !important;
    border-radius: 3px !important;
    padding: 6px 8px 6px 6px !important;
    font-size: 12px !important;
    opacity: 1 !important;
    line-height: 14px !important;
    text-decoration: none !important;
    transform: none !important;
    margin: 0 !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    white-space: nowrap;
    box-shadow: 0 0 0 1px rgba(0, 0, 0, .1), 0 1px 3px rgba(0, 0, 0, .1);
    cursor: pointer
}

.w-webflow-badge > img {
    display: inline-block !important;
    visibility: visible !important;
    opacity: 1 !important;
    vertical-align: middle !important
}

h1, h2, h3, h4, h5, h6 {
    font-weight: 700;
    margin-bottom: 10px
}

h5 {
    font-size: 14px;
    line-height: 20px;
    margin-top: 10px
}

h6 {
    font-size: 12px;
    line-height: 18px;
    margin-top: 10px
}

p {
    margin-top: 0;
    margin-bottom: 0
}

blockquote {
    margin: 0 0 10px;
    padding: 10px 20px;
    border-left: 5px solid #e2e2e2;
    font-size: 18px;
    line-height: 22px
}

figure {
    margin: 0 0 10px
}

figcaption {
    margin-top: 5px;
    text-align: center
}

ol, ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px
}

.w-list-unstyled {
    padding-left: 0;
    list-style: none
}

.w-embed:after, .w-embed:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-embed:after {
    clear: both
}

.w-video {
    width: 100%;
    position: relative;
    padding: 0
}

.w-video embed, .w-video iframe, .w-video object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

button, html input[type=button], input[type=reset] {
    -webkit-appearance: button;
    border: 0;
    cursor: pointer;
    -webkit-appearance: button
}

.w-form {
    margin: 0 0 15px
}

.w-form-done {
    display: none;
    padding: 20px;
    text-align: center;
    background-color: #ddd
}

.w-form-fail {
    display: none;
    margin-top: 10px;
    padding: 10px;
    background-color: #ffdede
}

.w-input, .w-select {
    display: block;
    width: 100%;
    height: 38px;
    padding: 8px 12px;
    margin-bottom: 10px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #333;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #ccc
}

.w-input:-moz-placeholder, .w-select:-moz-placeholder {
    color: #999
}

.w-input::-moz-placeholder, .w-select::-moz-placeholder {
    color: #999;
    opacity: 1
}

.w-input:-ms-input-placeholder, .w-select:-ms-input-placeholder {
    color: #999
}

.w-input::-webkit-input-placeholder, .w-select::-webkit-input-placeholder {
    color: #999
}

.w-input:focus, .w-select:focus {
    border-color: #3898ec;
    outline: 0
}

.w-input[disabled], .w-input[readonly], .w-select[disabled], .w-select[readonly], fieldset[disabled] .w-input, fieldset[disabled] .w-select {
    cursor: not-allowed;
    background-color: #eee
}

textarea.w-input, textarea.w-select {
    height: auto
}

.w-select {
    background-color: #f3f3f3
}

.w-select[multiple] {
    height: auto
}

.w-form-label {
    display: inline-block;
    cursor: pointer;
    font-weight: 400;
    margin-bottom: 0
}

.w-radio {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-radio:after, .w-radio:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-radio:after {
    clear: both
}

.w-radio-input {
    margin: 3px 0 0 -20px;
    line-height: normal;
    float: left
}

.w-file-upload {
    display: block;
    margin-bottom: 10px
}

.w-file-upload-input {
    width: .1px;
    height: .1px;
    opacity: 0;
    overflow: hidden;
    position: absolute;
    z-index: -100
}

.w-file-upload-default, .w-file-upload-success, .w-file-upload-uploading {
    display: inline-block;
    color: #333
}

.w-file-upload-error {
    display: block;
    margin-top: 10px
}

.w-file-upload-default.w-hidden, .w-file-upload-error.w-hidden, .w-file-upload-success.w-hidden, .w-file-upload-uploading.w-hidden {
    display: none
}

.w-file-upload-uploading-btn {
    display: flex;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-file-upload-file {
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    margin: 0;
    padding: 8px 9px 8px 11px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-file-upload-file-name {
    font-size: 14px;
    font-weight: 400;
    display: block
}

.w-file-remove-link {
    margin-top: 3px;
    margin-left: 10px;
    width: auto;
    height: auto;
    padding: 3px;
    display: block;
    cursor: pointer
}

.w-icon-file-upload-remove {
    margin: auto;
    font-size: 10px
}

.w-file-upload-error-msg {
    display: inline-block;
    color: #ea384c;
    padding: 2px 0
}

.w-file-upload-info {
    display: inline-block;
    line-height: 38px;
    padding: 0 12px
}

.w-file-upload-label {
    display: inline-block;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    margin: 0;
    padding: 8px 12px;
    border: 1px solid #ccc;
    background-color: #fafafa
}

.w-icon-file-upload-icon, .w-icon-file-upload-uploading {
    display: inline-block;
    margin-right: 8px;
    width: 20px
}

.w-icon-file-upload-uploading {
    height: 20px
}

.w-container {
    margin-left: auto;
    margin-right: auto;
    max-width: 940px
}

.w-container:after, .w-container:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-container:after {
    clear: both
}

.w-container .w-row {
    margin-left: -10px;
    margin-right: -10px
}

.w-row:after, .w-row:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-row:after {
    clear: both
}

.w-row .w-row {
    margin-left: 0;
    margin-right: 0
}

.w-col {
    position: relative;
    float: left;
    width: 100%;
    min-height: 1px;
    padding-left: 10px;
    padding-right: 10px
}

.w-col .w-col {
    padding-left: 0;
    padding-right: 0
}

.w-col-1 {
    width: 8.33333333%
}

.w-col-2 {
    width: 16.66666667%
}

.w-col-3 {
    width: 25%
}

.w-col-4 {
    width: 33.33333333%
}

.w-col-5 {
    width: 41.66666667%
}

.w-col-6 {
    width: 50%
}

.w-col-7 {
    width: 58.33333333%
}

.w-col-8 {
    width: 66.66666667%
}

.w-col-9 {
    width: 75%
}

.w-col-10 {
    width: 83.33333333%
}

.w-col-11 {
    width: 91.66666667%
}

.w-col-12 {
    width: 100%
}

.w-hidden-main {
    display: none !important
}

@media screen and (max-width: 991px) {
    .w-container {
        max-width: 728px
    }

    .w-hidden-main {
        display: inherit !important
    }

    .w-hidden-medium {
        display: none !important
    }

    .w-col-medium-1 {
        width: 8.33333333%
    }

    .w-col-medium-2 {
        width: 16.66666667%
    }

    .w-col-medium-3 {
        width: 25%
    }

    .w-col-medium-4 {
        width: 33.33333333%
    }

    .w-col-medium-5 {
        width: 41.66666667%
    }

    .w-col-medium-6 {
        width: 50%
    }

    .w-col-medium-7 {
        width: 58.33333333%
    }

    .w-col-medium-8 {
        width: 66.66666667%
    }

    .w-col-medium-9 {
        width: 75%
    }

    .w-col-medium-10 {
        width: 83.33333333%
    }

    .w-col-medium-11 {
        width: 91.66666667%
    }

    .w-col-medium-12 {
        width: 100%
    }

    .w-col-stack {
        width: 100%;
        left: auto;
        right: auto
    }
}

@media screen and (max-width: 767px) {
    .w-hidden-main, .w-hidden-medium {
        display: inherit !important
    }

    .w-hidden-small {
        display: none !important
    }

    .w-container .w-row, .w-row {
        margin-left: 0;
        margin-right: 0
    }

    .w-col {
        width: 100%;
        left: auto;
        right: auto
    }

    .w-col-small-1 {
        width: 8.33333333%
    }

    .w-col-small-2 {
        width: 16.66666667%
    }

    .w-col-small-3 {
        width: 25%
    }

    .w-col-small-4 {
        width: 33.33333333%
    }

    .w-col-small-5 {
        width: 41.66666667%
    }

    .w-col-small-6 {
        width: 50%
    }

    .w-col-small-7 {
        width: 58.33333333%
    }

    .w-col-small-8 {
        width: 66.66666667%
    }

    .w-col-small-9 {
        width: 75%
    }

    .w-col-small-10 {
        width: 83.33333333%
    }

    .w-col-small-11 {
        width: 91.66666667%
    }

    .w-col-small-12 {
        width: 100%
    }
}

@media screen and (max-width: 479px) {
    .w-container {
        max-width: none
    }

    .w-hidden-main, .w-hidden-medium, .w-hidden-small {
        display: inherit !important
    }

    .w-hidden-tiny {
        display: none !important
    }

    .w-col {
        width: 100%
    }

    .w-col-tiny-1 {
        width: 8.33333333%
    }

    .w-col-tiny-2 {
        width: 16.66666667%
    }

    .w-col-tiny-3 {
        width: 25%
    }

    .w-col-tiny-4 {
        width: 33.33333333%
    }

    .w-col-tiny-5 {
        width: 41.66666667%
    }

    .w-col-tiny-6 {
        width: 50%
    }

    .w-col-tiny-7 {
        width: 58.33333333%
    }

    .w-col-tiny-8 {
        width: 66.66666667%
    }

    .w-col-tiny-9 {
        width: 75%
    }

    .w-col-tiny-10 {
        width: 83.33333333%
    }

    .w-col-tiny-11 {
        width: 91.66666667%
    }

    .w-col-tiny-12 {
        width: 100%
    }
}

.w-widget {
    position: relative
}

.w-widget-map {
    width: 100%;
    height: 400px
}

.w-widget-map label {
    width: auto;
    display: inline
}

.w-widget-map img {
    max-width: inherit
}

.w-widget-map .gm-style-iw {
    text-align: center
}

.w-widget-map .gm-style-iw > button {
    display: none !important
}

.w-widget-twitter {
    overflow: hidden
}

.w-widget-twitter-count-shim {
    display: inline-block;
    vertical-align: top;
    position: relative;
    width: 28px;
    height: 20px;
    text-align: center;
    background: #fff;
    border: 1px solid #758696;
    border-radius: 3px
}

.w-widget-twitter-count-shim * {
    pointer-events: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-widget-twitter-count-shim .w-widget-twitter-count-inner {
    position: relative;
    font-size: 15px;
    line-height: 12px;
    text-align: center;
    color: #999;
    font-family: serif
}

.w-widget-twitter-count-shim .w-widget-twitter-count-clear {
    position: relative;
    display: block
}

.w-widget-twitter-count-shim.w--large {
    width: 36px;
    height: 28px
}

.w-widget-twitter-count-shim.w--large .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 18px
}

.w-widget-twitter-count-shim:not(.w--vertical) {
    margin-left: 5px;
    margin-right: 8px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large {
    margin-left: 6px
}

.w-widget-twitter-count-shim:not(.w--vertical):after, .w-widget-twitter-count-shim:not(.w--vertical):before {
    top: 50%;
    left: 0;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.w-widget-twitter-count-shim:not(.w--vertical):before {
    border-color: rgba(117, 134, 150, 0);
    border-right-color: #5d6c7b;
    border-width: 4px;
    margin-left: -9px;
    margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:before {
    border-width: 5px;
    margin-left: -10px;
    margin-top: -5px
}

.w-widget-twitter-count-shim:not(.w--vertical):after {
    border-color: rgba(255, 255, 255, 0);
    border-right-color: #fff;
    border-width: 4px;
    margin-left: -8px;
    margin-top: -4px
}

.w-widget-twitter-count-shim:not(.w--vertical).w--large:after {
    border-width: 5px;
    margin-left: -9px;
    margin-top: -5px
}

.w-widget-twitter-count-shim.w--vertical {
    width: 61px;
    height: 33px;
    margin-bottom: 8px
}

.w-widget-twitter-count-shim.w--vertical:after, .w-widget-twitter-count-shim.w--vertical:before {
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none
}

.w-widget-twitter-count-shim.w--vertical:before {
    border-color: rgba(117, 134, 150, 0);
    border-top-color: #5d6c7b;
    border-width: 5px;
    margin-left: -5px
}

.w-widget-twitter-count-shim.w--vertical:after {
    border-color: rgba(255, 255, 255, 0);
    border-top-color: #fff;
    border-width: 4px;
    margin-left: -4px
}

.w-widget-twitter-count-shim.w--vertical .w-widget-twitter-count-inner {
    font-size: 18px;
    line-height: 22px
}

.w-widget-twitter-count-shim.w--vertical.w--large {
    width: 76px
}

.w-widget-gplus {
    overflow: hidden
}

.w-background-video {
    position: relative;
    overflow: hidden;
    height: 500px;
    color: #fff
}

.w-background-video > video {
    background-size: cover;
    background-position: 50% 50%;
    position: absolute;
    margin: auto;
    width: 100%;
    height: 100%;
    right: -100%;
    bottom: -100%;
    top: -100%;
    left: -100%;
    object-fit: cover;
    z-index: -100
}

.w-background-video > video::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none
}

.w-slider {
    position: relative;
    height: 300px;
    text-align: center;
    background: #ddd;
    clear: both;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0)
}

.w-slider-mask {
    position: relative;
    display: block;
    overflow: hidden;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    white-space: nowrap
}

.w-slide {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 100%;
    height: 100%;
    white-space: normal;
    text-align: left
}

.w-slider-nav {
    position: absolute;
    z-index: 2;
    top: auto;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    padding-top: 10px;
    height: 40px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0)
}

.w-slider-nav.w-round > div {
    border-radius: 100%
}

.w-slider-nav.w-num > div {
    width: auto;
    height: auto;
    padding: .2em .5em;
    font-size: inherit;
    line-height: inherit
}

.w-slider-nav.w-shadow > div {
    box-shadow: 0 0 3px rgba(51, 51, 51, .4)
}

.w-slider-nav-invert {
    color: #fff
}

.w-slider-nav-invert > div {
    background-color: rgba(34, 34, 34, .4)
}

.w-slider-nav-invert > div.w-active {
    background-color: #222
}

.w-slider-dot {
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    background-color: rgba(255, 255, 255, .4);
    cursor: pointer;
    margin: 0 3px .5em;
    transition: background-color .1s, color .1s
}

.w-slider-dot.w-active {
    background-color: #fff
}

.w-slider-dot:focus {
    outline: 0;
    box-shadow: 0 0 0 2px #fff
}

.w-slider-dot:focus.w-active {
    box-shadow: none
}

.w-slider-arrow-left, .w-slider-arrow-right {
    position: absolute;
    width: 80px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    cursor: pointer;
    overflow: hidden;
    color: #fff;
    font-size: 40px;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-slider-arrow-left [class*=' w-icon-'], .w-slider-arrow-left [class^=w-icon-], .w-slider-arrow-right [class*=' w-icon-'], .w-slider-arrow-right [class^=w-icon-] {
    position: absolute
}

.w-slider-arrow-left:focus, .w-slider-arrow-right:focus {
    outline: 0
}

.w-slider-arrow-left {
    z-index: 3;
    right: auto
}

.w-slider-arrow-right {
    z-index: 4;
    left: auto
}

.w-icon-slider-left, .w-icon-slider-right {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 1em;
    height: 1em
}

.w-slider-aria-label {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px
}

.w-slider-force-show {
    display: block !important
}

.w-dropdown {
    display: inline-block;
    position: relative;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    z-index: 900
}

.w-dropdown-btn, .w-dropdown-link, .w-dropdown-toggle {
    position: relative;
    vertical-align: top;
    text-decoration: none;
    color: #222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    white-space: nowrap
}

.w-dropdown-toggle {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    display: inline-block;
    cursor: pointer;
    padding-right: 40px
}

.w-dropdown-toggle:focus {
    outline: 0
}

.w-icon-dropdown-toggle {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto 20px auto auto;
    width: 1em;
    height: 1em
}

.w-dropdown-list {
    position: absolute;
    background: #ddd;
    display: none;
    min-width: 100%
}

.w-dropdown-list.w--open {
    display: block
}

.w-dropdown-link {
    padding: 10px 20px;
    display: block;
    color: #222
}

.w-dropdown-link.w--current {
    color: #0082f3
}

.w-dropdown-link:focus {
    outline: 0
}

@media screen and (max-width: 767px) {
    .w-nav-brand {
        padding-left: 10px
    }
}

.w-lightbox-backdrop {
    cursor: auto;
    font-style: normal;
    font-variant: normal;
    letter-spacing: normal;
    list-style: disc;
    text-indent: 0;
    text-shadow: none;
    text-transform: none;
    visibility: visible;
    white-space: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: #fff;
    font-family: "Helvetica Neue", Helvetica, Ubuntu, "Segoe UI", Verdana, sans-serif;
    font-size: 17px;
    line-height: 1.2;
    font-weight: 300;
    text-align: center;
    background: rgba(0, 0, 0, .9);
    z-index: 2000;
    outline: 0;
    opacity: 0;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-tap-highlight-color: transparent;
    -webkit-transform: translate(0, 0)
}

.w-lightbox-backdrop, .w-lightbox-container {
    height: 100%;
    overflow: auto;
    -webkit-overflow-scrolling: touch
}

.w-lightbox-content {
    position: relative;
    height: 100vh;
    overflow: hidden
}

.w-lightbox-view {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0
}

.w-lightbox-view:before {
    content: "";
    height: 100vh
}

.w-lightbox-group, .w-lightbox-group .w-lightbox-view, .w-lightbox-group .w-lightbox-view:before {
    height: 86vh
}

.w-lightbox-frame, .w-lightbox-view:before {
    display: inline-block;
    vertical-align: middle
}

.w-lightbox-figure {
    position: relative;
    margin: 0
}

.w-lightbox-group .w-lightbox-figure {
    cursor: pointer
}

.w-lightbox-img {
    width: auto;
    height: auto;
    max-width: none
}

.w-lightbox-image {
    display: block;
    float: none;
    max-width: 100vw;
    max-height: 100vh
}

.w-lightbox-group .w-lightbox-image {
    max-height: 86vh
}

.w-lightbox-caption {
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    padding: .5em 1em;
    background: rgba(0, 0, 0, .4);
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden
}

.w-lightbox-embed {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.w-lightbox-control {
    position: absolute;
    top: 0;
    width: 4em;
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    -webkit-transition: .3s;
    transition: .3s
}

.w-lightbox-left {
    display: none;
    bottom: 0;
    left: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii0yMCAwIDI0IDQwIiB3aWR0aD0iMjQiIGhlaWdodD0iNDAiPjxnIHRyYW5zZm9ybT0icm90YXRlKDQ1KSI+PHBhdGggZD0ibTAgMGg1djIzaDIzdjVoLTI4eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDN2MjNoMjN2M2gtMjZ6IiBmaWxsPSIjZmZmIi8+PC9nPjwvc3ZnPg==")
}

.w-lightbox-right {
    display: none;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMjQgNDAiIHdpZHRoPSIyNCIgaGVpZ2h0PSI0MCI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMC0waDI4djI4aC01di0yM2gtMjN6IiBvcGFjaXR5PSIuNCIvPjxwYXRoIGQ9Im0xIDFoMjZ2MjZoLTN2LTIzaC0yM3oiIGZpbGw9IiNmZmYiLz48L2c+PC9zdmc+")
}

.w-lightbox-close {
    right: 0;
    height: 2.6em;
    background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9Ii00IDAgMTggMTciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxNyI+PGcgdHJhbnNmb3JtPSJyb3RhdGUoNDUpIj48cGF0aCBkPSJtMCAwaDd2LTdoNXY3aDd2NWgtN3Y3aC01di03aC03eiIgb3BhY2l0eT0iLjQiLz48cGF0aCBkPSJtMSAxaDd2LTdoM3Y3aDd2M2gtN3Y3aC0zdi03aC03eiIgZmlsbD0iI2ZmZiIvPjwvZz48L3N2Zz4=");
    background-size: 18px
}

.w-lightbox-strip {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0 1vh;
    line-height: 0;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden
}

.w-lightbox-item {
    display: inline-block;
    width: 10vh;
    padding: 2vh 1vh;
    box-sizing: content-box;
    cursor: pointer;
    -webkit-transform: translate3d(0, 0, 0)
}

.w-lightbox-active {
    opacity: .3
}

.w-lightbox-thumbnail {
    position: relative;
    height: 10vh;
    background: #222;
    overflow: hidden
}

.w-lightbox-thumbnail-image {
    position: absolute;
    top: 0;
    left: 0
}

.w-lightbox-thumbnail .w-lightbox-tall {
    top: 50%;
    width: 100%;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.w-lightbox-thumbnail .w-lightbox-wide {
    left: 50%;
    height: 100%;
    -webkit-transform: translate(-50%, 0);
    -ms-transform: translate(-50%, 0);
    transform: translate(-50%, 0)
}

.w-lightbox-spinner {
    position: absolute;
    top: 50%;
    left: 50%;
    box-sizing: border-box;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
    border: 5px solid rgba(0, 0, 0, .4);
    border-radius: 50%;
    -webkit-animation: .8s linear infinite spin;
    animation: .8s linear infinite spin
}

.w-lightbox-spinner:after {
    content: "";
    position: absolute;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    border: 3px solid transparent;
    border-bottom-color: #fff;
    border-radius: 50%
}

.w-lightbox-hide {
    display: none
}

.w-lightbox-noscroll {
    overflow: hidden
}

@media (min-width: 768px) {
    .w-lightbox-content {
        height: 96vh;
        margin-top: 2vh
    }

    .w-lightbox-view, .w-lightbox-view:before {
        height: 96vh
    }

    .w-lightbox-group, .w-lightbox-group .w-lightbox-view, .w-lightbox-group .w-lightbox-view:before {
        height: 84vh
    }

    .w-lightbox-image {
        max-width: 96vw;
        max-height: 96vh
    }

    .w-lightbox-group .w-lightbox-image {
        max-width: 82.3vw;
        max-height: 84vh
    }

    .w-lightbox-left, .w-lightbox-right {
        display: block;
        opacity: .5
    }

    .w-lightbox-close {
        opacity: .8
    }

    .w-lightbox-control:hover {
        opacity: 1
    }
}

.w-lightbox-inactive, .w-lightbox-inactive:hover {
    opacity: 0
}

.w-richtext:after, .w-richtext:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-richtext:after {
    clear: both
}

.w-richtext[contenteditable=true]:after, .w-richtext[contenteditable=true]:before {
    white-space: initial
}

.w-richtext ol, .w-richtext ul {
    overflow: hidden
}

.w-richtext .w-richtext-figure-selected.w-richtext-figure-type-image div, .w-richtext .w-richtext-figure-selected.w-richtext-figure-type-video div:after, .w-richtext .w-richtext-figure-selected[data-rt-type=image] div, .w-richtext .w-richtext-figure-selected[data-rt-type=video] div:after {
    outline: #2895f7 solid 2px
}

.w-richtext figure.w-richtext-figure-type-video > div:after, .w-richtext figure[data-rt-type=video] > div:after {
    content: '';
    position: absolute;
    display: none;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0
}

.w-richtext figure {
    position: relative;
    max-width: 60%
}

.w-richtext figure > div:before {
    cursor: default !important
}

.w-richtext figure img {
    width: 100%
}

.w-richtext figure figcaption.w-richtext-figcaption-placeholder {
    opacity: .6
}

.w-richtext figure div {
    font-size: 0;
    color: transparent
}

.w-richtext figure.w-richtext-figure-type-image, .w-richtext figure[data-rt-type=image] {
    display: table
}

.w-richtext figure.w-richtext-figure-type-image > div, .w-richtext figure[data-rt-type=image] > div {
    display: inline-block
}

.w-richtext figure.w-richtext-figure-type-image > figcaption, .w-richtext figure[data-rt-type=image] > figcaption {
    display: table-caption;
    caption-side: bottom
}

.w-richtext figure.w-richtext-figure-type-video, .w-richtext figure[data-rt-type=video] {
    width: 60%;
    height: 0
}

.w-richtext figure.w-richtext-figure-type-video iframe, .w-richtext figure[data-rt-type=video] iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.w-richtext figure.w-richtext-figure-type-video > div, .w-richtext figure[data-rt-type=video] > div {
    width: 100%
}

.w-richtext figure.w-richtext-align-center {
    margin-right: auto;
    margin-left: auto;
    clear: both
}

.w-richtext figure.w-richtext-align-center.w-richtext-figure-type-image > div, .w-richtext figure.w-richtext-align-center[data-rt-type=image] > div {
    max-width: 100%
}

.w-richtext figure.w-richtext-align-normal {
    clear: both
}

.w-richtext figure.w-richtext-align-fullwidth {
    width: 100%;
    max-width: 100%;
    text-align: center;
    clear: both;
    display: block;
    margin-right: auto;
    margin-left: auto
}

.w-richtext figure.w-richtext-align-fullwidth > div {
    display: inline-block;
    padding-bottom: inherit
}

.w-richtext figure.w-richtext-align-fullwidth > figcaption {
    display: block
}

.w-richtext figure.w-richtext-align-floatleft {
    float: left;
    margin-right: 15px;
    clear: none
}

.w-richtext figure.w-richtext-align-floatright {
    float: right;
    margin-left: 15px;
    clear: none
}

.w-nav {
    position: relative;
    background: #ddd;
    z-index: 1000
}

.w-nav:after, .w-nav:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-nav:after {
    clear: both
}

.w-nav-brand {
    position: relative;
    float: left;
    text-decoration: none;
    color: #333
}

.w-nav-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    color: #222;
    padding: 20px;
    text-align: left;
    margin-left: auto;
    margin-right: auto
}

.w-nav-link.w--current {
    color: #0082f3
}

.w-nav-menu {
    position: relative;
    float: right
}

[data-nav-menu-open] {
    display: block !important;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background: #c8c8c8;
    text-align: center;
    overflow: visible;
    min-width: 200px
}

.w--nav-link-open {
    display: block;
    position: relative
}

.w-nav-overlay {
    position: absolute;
    overflow: hidden;
    display: none;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%
}

.w-nav-overlay [data-nav-menu-open] {
    top: 0
}

.w-nav[data-animation=over-left] .w-nav-overlay {
    width: auto
}

.w-nav[data-animation=over-left] .w-nav-overlay, .w-nav[data-animation=over-left] [data-nav-menu-open] {
    right: auto;
    z-index: 1;
    top: 0
}

.w-nav[data-animation=over-right] .w-nav-overlay {
    width: auto
}

.w-nav[data-animation=over-right] .w-nav-overlay, .w-nav[data-animation=over-right] [data-nav-menu-open] {
    left: auto;
    z-index: 1;
    top: 0
}

.w-nav-button {
    position: relative;
    float: right;
    padding: 18px;
    font-size: 24px;
    display: none;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
    tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

.w-nav-button:focus {
    outline: 0
}

.w-nav-button.w--open {
    background-color: #c8c8c8;
    color: #fff
}

.w-nav[data-collapse=all] .w-nav-menu {
    display: none
}

.w--nav-dropdown-open, .w--nav-dropdown-toggle-open, .w-nav[data-collapse=all] .w-nav-button {
    display: block
}

.w--nav-dropdown-list-open {
    position: static
}

@media screen and (max-width: 991px) {
    .w-nav[data-collapse=medium] .w-nav-menu {
        display: none
    }

    .w-nav[data-collapse=medium] .w-nav-button {
        display: block
    }
}

@media screen and (max-width: 767px) {
    .w-nav[data-collapse=small] .w-nav-menu {
        display: none
    }

    .w-nav[data-collapse=small] .w-nav-button {
        display: block
    }

    .w-nav-brand {
        padding-left: 10px
    }
}

.w-tabs {
    position: relative
}

.w-tabs:after, .w-tabs:before {
    content: " ";
    display: table;
    grid-column-start: 1;
    grid-row-start: 1;
    grid-column-end: 2;
    grid-row-end: 2
}

.w-tabs:after {
    clear: both
}

.w-tab-menu {
    position: relative
}

.w-tab-link {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    padding: 9px 30px;
    text-align: left;
    cursor: pointer;
    color: #222;
    background-color: #ddd
}

.w-tab-link.w--current {
    background-color: #c8c8c8
}

.w-tab-link:focus {
    outline: 0
}

.w-tab-content {
    position: relative;
    display: block;
    overflow: hidden
}

.w-tab-pane {
    position: relative;
    display: none
}

.w--tab-active {
    display: block
}

@media screen and (max-width: 479px) {
    .w-nav[data-collapse=tiny] .w-nav-menu {
        display: none
    }

    .w-nav[data-collapse=tiny] .w-nav-button, .w-tab-link {
        display: block
    }
}

.w-ix-emptyfix:after {
    content: ""
}

@keyframes spin {
    0% {
        transform: rotate(0)
    }
    100% {
        transform: rotate(360deg)
    }
}

.w-dyn-empty {
    padding: 10px;
    background-color: #ddd
}

.w-condition-invisible, .w-dyn-bind-empty, .w-dyn-hide {
    display: none !important
}

.w-layout-grid {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows:auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px
}

.w-checkbox {
    display: block;
    margin-bottom: 5px;
    padding-left: 20px
}

.w-checkbox::before {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1
}

.w-checkbox::after {
    content: ' ';
    display: table;
    -ms-grid-column-span: 1;
    grid-column-end: 2;
    -ms-grid-column: 1;
    grid-column-start: 1;
    -ms-grid-row-span: 1;
    grid-row-end: 2;
    -ms-grid-row: 1;
    grid-row-start: 1;
    clear: both
}

.w-checkbox-input {
    float: left;
    margin: 4px 0 0 -20px;
    line-height: normal
}

.w-checkbox-input--inputType-custom {
    border-width: 1px;
    border-color: #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-style: solid;
    width: 12px;
    height: 12px;
    border-radius: 2px
}

.w-checkbox-input--inputType-custom.w--redirected-checked {
    background-color: #3898ec;
    border-color: #3898ec;
    background-image: url(https://d3e54v103j8qbb.cloudfront.net/static/custom-checkbox-checkmark.589d534424.svg);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat
}

.w-checkbox-input--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

.w-form-formradioinput--inputType-custom {
    border-width: 1px;
    border-color: #ccc;
    border-top: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-style: solid;
    width: 12px;
    height: 12px;
    border-radius: 50%
}

.w-form-formradioinput--inputType-custom.w--redirected-focus {
    box-shadow: 0 0 3px 1px #3898ec
}

.w-form-formradioinput--inputType-custom.w--redirected-checked {
    border-width: 4px;
    border-color: #3898ec
}

body {
    margin: 0;
    min-height: 100%;
    background-color: #fff;
    font-family: Inter, sans-serif;
    color: #1d1d1f;
    font-size: 16px;
    line-height: 1.2
}

h1 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 38px;
    line-height: 44px;
    font-weight: 600
}

h2 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 32px;
    line-height: 36px;
    font-weight: 600
}

h3 {
    margin-top: 20px;
    margin-bottom: 10px;
    font-size: 24px;
    line-height: 30px;
    font-weight: 600
}

h4 {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600
}

ul {
    margin-top: 0;
    margin-bottom: 10px;
    padding-left: 40px
}

label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500
}

strong {
    font-weight: 500
}

.nav {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: 100;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e2e2;
    background-color: hsla(0, 0%, 100%, .7)
}

.container {
    width: 90%;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto
}

.container.container--nav {
    position: relative;
    text-align: center
}

.container.container--center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.container.container--hero {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    max-width: none;
    padding-top: 100px;
    padding-bottom: 100px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.container.container--workflow {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.container.container--legal {
    max-width: 680px
}

.container.container--large {
    width: 100%;
    max-width: 1440px;
    padding-right: 28px;
    padding-left: 28px
}

.link__bg {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: inline-block;
    border: 1px solid #e9e9e9;
    border-radius: 8px;
    background-color: #f7f7f7;
    opacity: 0;
    color: #1d1d1f;
    text-decoration: none
}

.link__bg.link__bg--hero {
    border-radius: 12px
}

.nav__logo {
    position: absolute;
    left: 0;
    top: 0;
    right: auto;
    bottom: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-left: -18px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.025em;
    text-decoration: none
}

.nav__link {
    position: relative;
    z-index: 102;
    display: inline-block;
    padding: 8px 18px;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.011em;
    text-decoration: none
}

.nav__link.w--current {
    font-weight: 500
}

.link__content {
    position: relative;
    z-index: 102;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: perspective(0);
    transform: perspective(0)
}

.link__content:active {
    opacity: .7
}

.cta-link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    color: #006ddb;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.025em;
    text-decoration: none;
    white-space: nowrap
}

.cta-link.cta-link--hero {
    position: relative;
    z-index: 10;
    padding: 10px 28px;
    font-size: 23px
}

.cta-link.cta__link--nav {
    position: absolute;
    left: auto;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 102;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-right: -18px;
    padding: 8px 18px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 16px
}

.cta-link.cta-link--form {
    position: relative;
    z-index: 203;
    padding: 10px 28px;
    font-size: 21px
}

.hero {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100vh;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.hero__header {
    position: relative;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.hero-heading {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 92px;
    line-height: 1.05;
    letter-spacing: -.045em
}

.hero-img-container {
    position: relative;
    z-index: 3;
    width: 100%;
    height: 100%;
    padding: 12px;
    border: 1px solid hsla(0, 0%, 100%, .3);
    border-radius: 50%;
    background-color: hsla(0, 0%, 84%, .5);
    -o-object-fit: cover;
    object-fit: cover
}

.section {
    padding-top: 140px;
    padding-bottom: 140px
}

.section.section--gray.section--faq {
    padding-top: 0
}

.section.section--workflow {
    overflow: hidden;
    height: 100vh
}

.section.section--manifest {
    padding-top: 100px;
    padding-bottom: 160px
}

.section.section--faq {
    padding-top: 80px
}

.section-heading {
    max-width: 890px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 92px;
    line-height: 1.1;
    letter-spacing: -.045em
}

.section-heading.section-heading--faq {
    max-width: none;
    font-size: 80px;
    text-align: center
}

.intro-card-description {
    width: 100%;
    margin-top: 24px;
    margin-bottom: 0;
    opacity: .75;
    font-size: 20px;
    line-height: 1.5;
    font-weight: 500;
    letter-spacing: -.025em
}

.intro-card-description.intro-card-description--animations {
    max-width: 390px
}

.anim-video {
    position: absolute;
    left: 1%;
    top: 1.4%;
    z-index: 1;
    width: 98%;
    height: 73.5%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.anim-video-wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    max-width: 500px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.anim-video-display {
    position: relative;
    z-index: 2;
    width: 100%
}

.anim-video-container {
    position: relative;
    width: 100%
}

.projects-list-wrapper {
    width: 100%;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.sticky-wrap {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1
}

.sticky-wrap.sticky-wrap--hero {
    height: 60vw;
    min-height: 100vh
}

.workflow-scroll-wrap {
    height: 300vh
}

.projects-list-item {
    width: 100%;
    height: 100%;
    -webkit-perspective: 1500px;
    perspective: 1500px;
    -webkit-transition: -webkit-transform .1s;
    transition: transform .1s;
    transition: transform .1s, -webkit-transform .1s
}

.projects-list-item:active {
    -webkit-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1)
}

.projects-list-item-link {
    position: relative;
    z-index: 2;
    display: block;
    overflow: hidden;
    width: 100%;
    border: 1px solid hsla(0, 0%, 62%, .2);
    border-radius: 22px;
    -webkit-transition: box-shadow .5s;
    transition: box-shadow .5s;
    color: #000
}

.projects-list-item-link:hover {
    box-shadow: 0 20px 80px 0 rgba(0, 0, 0, .3)
}

.projects-list-item-link.lift {
    text-decoration: none
}

.projects-list {
    position: relative;
    display: -ms-grid;
    display: grid;
    width: 100%;
    grid-auto-columns: 1fr;
    grid-column-gap: 28px;
    grid-row-gap: 28px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows:auto auto
}

.section-header {
    width: 90%;
    max-width: 980px;
    margin-right: auto;
    margin-bottom: 142px;
    margin-left: auto;
    text-align: left
}

.section-header.section-header--workflow {
    position: absolute;
    width: 100%
}

.section-header.section-header--intro {
    position: relative;
    max-width: 1050px;
    margin-bottom: 0
}

.section-header.section-header--faq {
    margin-bottom: 64px
}

.workflow-card {
    position: absolute;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 380px;
    max-width: 750px;
    padding: 65px 80px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 28px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 58.8%, .2);
    -webkit-transform-origin: 50% 0%;
    -ms-transform-origin: 50% 0%;
    transform-origin: 50% 0%
}

.workflow-card-step {
    margin-bottom: 8px;
    color: #006ddb;
    font-weight: 500;
    letter-spacing: -.015em
}

.workflow-card__heading {
    margin-top: 0;
    margin-bottom: 12px;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -.015em
}

.nav__logo__link {
    position: relative;
    z-index: 102;
    display: block;
    padding: 7px 18px;
    text-decoration: none
}

.workflow-cards-wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.workflow-card__launch-konfetti {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%)
}

.settings-wrap {
    position: fixed;
    left: 20px;
    top: auto;
    right: auto;
    bottom: 20px;
    z-index: 100;
    display: block;
    overflow: hidden
}

.settings__inner {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    opacity: 1
}

.settings__menu-section {
    margin-right: 18px;
    margin-bottom: 24px;
    margin-left: 18px;
    padding-top: 4px;
    padding-bottom: 4px;
    border: .5px solid hsla(0, 0%, 100%, .1);
    border-radius: 12px;
    background-color: rgba(255, 255, 255, .4);
    font-size: 14px;
    font-weight: 500;
    text-align: center
}

.more-menu__link-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 0;
    padding-left: 0;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.more-menu__link {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    margin-right: 4px;
    margin-left: 4px;
    padding: 10px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 8px;
    color: #1d1d1f;
    font-size: 14px;
    text-decoration: none
}

.more-menu__link.w--current {
    background-color: #1d1d1f
}

.more-menu__link.more-menu__link--active {
    background-color: #fff
}

.more-menu__link.language.w--current {
    background-color: rgba(255, 255, 255, .8);
    color: #1d1d1f
}

.more-menu__icon {
    width: 18px;
    margin-right: 8px
}

.anfrage-overlay-wrap {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
    display: none;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows:auto auto;
    background-color: #ddd
}

.anfrage-wrap {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    width: 90%;
    height: 90vh;
    max-height: 720px;
    max-width: 980px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    border-radius: 28px;
    background-color: #fff
}

.form-slider {
    position: static;
    width: 100%;
    height: 100%;
    background-color: transparent
}

.mask {
    position: static;
    height: 100%
}

.form-slide {
    height: 100%;
    padding-bottom: 32px
}

.anfrage-bottom {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 20px;
    padding-bottom: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.form-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 520px;
    margin-right: auto;
    margin-left: auto;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.form-heading {
    margin-top: 0;
    margin-bottom: 16px;
    font-size: 40px;
    line-height: 1.05;
    font-weight: 600;
    letter-spacing: -.025em
}

.form-intro-img {
    width: 150px;
    height: 150px;
    margin: 4px;
    border-radius: 50%
}

.anfrage-form-block {
    z-index: 1;
    overflow: hidden;
    margin-bottom: 0;
    padding-bottom: 74px;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.anfrage-form {
    height: 100%
}

.form-eyebrow {
    margin-bottom: 8px;
    opacity: .7;
    font-weight: 600;
    letter-spacing: -.01em
}

.form-checkbox-wrap {
    margin-top: 32px
}

.form-checkbox-field {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-height: 64px;
    margin-bottom: 20px;
    padding: 16px 56px 16px 28px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 18px;
    background-color: #f5f5f7;
    cursor: none
}

.form-checkbox-title {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.02em
}

.form-checkbox {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    margin-top: 0;
    margin-left: 0;
    border-width: 2px;
    border-color: hsla(0, 0%, 53.3%, .2);
    border-radius: 18px;
    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/605739cf94ca6b11afae090e_check%20initial.svg");
    background-position: 100% 50%;
    background-size: auto;
    background-repeat: no-repeat
}

.form-checkbox.w--redirected-checked {
    border: 2px solid #006ddb;
    border-radius: 18px;
    background-color: transparent;
    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/605739cf94ca6b0b55ae090f_check%20checked.svg");
    background-position: 100% 50%;
    background-size: auto
}

.anfrage-intro {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 202;
    display: none;
    overflow: auto;
    padding: 32px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 24px;
    background-color: #fff
}

.anfrage-start-button {
    position: relative;
    margin-top: 36px
}

.anfrage-nav-button-wrap {
    position: absolute;
    left: auto;
    top: 15px;
    right: 22px;
    bottom: auto;
    z-index: 203;
    font-weight: 500
}

.anfrage-nav-button-wrap.anfrage__abbruch--success {
    position: relative;
    left: auto;
    top: auto;
    right: 0;
    padding: 0
}

.anfrage-nav-button-wrap.anfrage-nav-button-wrap--back {
    left: 22px;
    top: 15px;
    right: auto;
    bottom: auto;
    opacity: 0
}

.form-content {
    padding-top: 33px
}

.form-field-wrap {
    margin-bottom: 22px
}

.form-field {
    height: 48px;
    margin-bottom: 0;
    padding-right: 18px;
    padding-left: 18px;
    border: 2px solid hsla(0, 0%, 53.3%, .2);
    border-radius: 14px;
    background-color: #f5f5f7;
    color: #1d1d1f;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: -.02em;
    cursor: none
}

.form-field:focus {
    border-width: 2px;
    border-color: #006ddb
}

.form-field.form__field--area {
    height: auto;
    max-width: 520px;
    min-height: 250px
}

.form-field-label {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-weight: 500;
    letter-spacing: -.025em
}

.anfrage__datenschutz-check {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    font-size: 12px;
    letter-spacing: -.015em;
    cursor: none
}

.link {
    color: #006ddb;
    letter-spacing: -.2px;
    text-transform: none
}

.anfrage__datenschutz-checkbox {
    width: 14px;
    height: 14px;
    min-width: 14px;
    margin-top: 2px;
    margin-right: 8px;
    border-color: #cecece;
    border-radius: 4px;
    background-color: #fafafa;
    box-shadow: inset 1px 1px 0 -.5px #fff, 1px 1px 2px -2px rgba(0, 0, 0, .1);
    cursor: none
}

.anfrage__datenschutz-checkbox.w--redirected-checked {
    border-color: #006ddb;
    border-radius: 4px;
    background-color: #006ddb;
    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/605739cf94ca6b32bdae0874_check-filled-2.svg");
    box-shadow: 1px 1px 2px -2px rgba(0, 0, 0, .1)
}

.anfrage-form__success {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: #fff
}

.anfrage__success-trigger {
    width: 0;
    height: 0
}

.anfrage-form__submit {
    display: none
}

.footer-menu-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 18px;
    padding-bottom: 18px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: -.011em
}

.footer__link-wrap {
    margin-right: -12px
}

.footer-link {
    display: inline-block;
    padding: 6px 12px;
    opacity: .7;
    text-decoration: none
}

.footer-link:hover {
    opacity: 1
}

.footer-cta-wrap {
    display: block;
    padding-top: 240px;
    padding-bottom: 240px;
    color: #1d1d1f;
    text-decoration: none
}

.utility-page-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100vw;
    height: 100vh;
    max-height: 100%;
    max-width: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

._404-heading {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 48px;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: -.04em
}

._404-link {
    position: relative;
    margin-top: 32px
}

.legal-text {
    font-weight: 500;
    letter-spacing: -.015em
}

.legal-text h1 {
    margin-top: 0;
    margin-bottom: 48px;
    padding-bottom: 22px;
    border-bottom: 1px solid hsla(0, 0%, 68.7%, .4);
    font-size: 80px;
    line-height: 1.2;
    letter-spacing: -.04em
}

.legal-text h2 {
    margin-top: 32px;
    margin-bottom: 12px;
    font-size: 24px;
    line-height: 1.2;
    letter-spacing: -.025em
}

.legal-text p {
    margin-bottom: 12px;
    opacity: .7;
    line-height: 1.5;
    letter-spacing: -.015em
}

.legal-text h3 {
    margin-top: 32px;
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 1.3
}

.legal-text h4 {
    margin-top: 18px;
    margin-bottom: 8px;
    font-size: 16px;
    font-weight: 600
}

.legal-text li {
    margin-bottom: 12px;
    opacity: .8;
    line-height: 1.5;
    list-style-type: disc
}

.language-notice-wrap {
    position: fixed;
    left: 16px;
    top: 68px;
    right: 16px;
    bottom: auto;
    z-index: 999;
    display: none;
    max-width: 700px;
    margin-right: auto;
    margin-left: auto;
    padding: 29px 42px;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -webkit-flex-direction: row;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 16px;
    background-color: hsla(0, 0%, 59.2%, .3);
    text-align: center
}

.language-notice-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    letter-spacing: -.025em
}

.language-notice-button {
    display: inline-block;
    padding: 8px 20px;
    border-radius: 10px;
    background-color: #006ddb;
    font-weight: 500;
    cursor: none
}

.language-notice-cancel {
    margin-left: 12px;
    padding: 8px 10px;
    text-align: center
}

.language-notice-trigger {
    width: 0;
    height: 0
}

.hero-img-wrap {
    position: relative;
    z-index: 2;
    width: 25vw;
    height: 25vw;
    max-height: 40vh;
    max-width: 40vh;
    min-height: 350px;
    min-width: 350px;
    margin-bottom: 64px
}

.hero-img-shadow {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-image: radial-gradient(circle farthest-side at 100% 100%, #9873ff, rgba(152, 115, 255, 0)), radial-gradient(circle farthest-side at 100% 0, #0ba5f7, rgba(85, 196, 255, 0)), radial-gradient(circle farthest-side at 0 0, #ff763c, rgba(255, 176, 60, 0)), radial-gradient(circle farthest-side at 0 100%, #ff5aaa, rgba(255, 90, 170, 0))
}

.hero-img-shadow-wrap {
    position: absolute;
    left: -20%;
    top: -20%;
    right: 0;
    bottom: 0;
    width: 140%;
    height: 140%;
    padding: 20%;
    border-radius: 50%;
    -webkit-filter: blur(20px);
    filter: blur(20px)
}

.hero-img-shadow-wrap.hero-img-shadow-wrap--intro {
    z-index: 1
}

.settings-handle {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 72px;
    height: 44px;
    padding: 12px 26px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end;
    border-radius: 24px;
    background-color: hsla(0, 0%, 59.2%, .3);
    font-size: 14px
}

.hero-img {
    display: block;
    width: 100%;
    height: 100%;
    max-width: none;
    border-radius: 50%
}

/*.cursor {*/
/*    position: fixed;*/
/*    z-index: 1000000;*/
/*    width: 20px;*/
/*    height: 20px;*/
/*    opacity: 0;*/
/*    -webkit-backface-visibility: hidden;*/
/*    backface-visibility: hidden;*/
/*    -webkit-transition: opacity .2s cubic-bezier(.215, .61, .355, 1), height .2s cubic-bezier(.215, .61, .355, 1), width .2s cubic-bezier(.215, .61, .355, 1);*/
/*    transition: opacity .2s cubic-bezier(.215, .61, .355, 1), height .2s cubic-bezier(.215, .61, .355, 1), width .2s cubic-bezier(.215, .61, .355, 1)*/
/*}*/

.cursor-content-wrap {
    width: 100%;
    height: 100%;
    -webkit-transition: opacity .2s cubic-bezier(.215, .61, .355, 1), -webkit-transform .2s cubic-bezier(.215, .61, .355, 1);
    transition: opacity .2s cubic-bezier(.215, .61, .355, 1), transform .2s cubic-bezier(.215, .61, .355, 1), -webkit-transform .2s cubic-bezier(.215, .61, .355, 1)
}

.cursor__content {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: #989898;
    opacity: .5;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    -webkit-transition: opacity .2s cubic-bezier(.215, .61, .355, 1), height .2s cubic-bezier(.215, .61, .355, 1), width .2s cubic-bezier(.215, .61, .355, 1);
    transition: opacity .2s cubic-bezier(.215, .61, .355, 1), height .2s cubic-bezier(.215, .61, .355, 1), width .2s cubic-bezier(.215, .61, .355, 1)
}

.cursor-highlight {
    position: fixed;
    z-index: 1001;
    border-radius: 50%;
    background-color: #fff;
    opacity: 0;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%)
}

.hero-eyebrow {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 25px;
    line-height: 1.2;
    letter-spacing: -.03em
}

.intro-grid {
    margin-bottom: 28px;
    grid-column-gap: 28px;
    grid-row-gap: 28px
}

.intro-grid.intro-grid--variant {
    -ms-grid-columns: 0.4fr 1fr;
    grid-template-columns:0.4fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto
}

.intro-card {
    position: relative;
    padding: 64px;
    border: 1px solid hsla(0, 0%, 51.4%, .16);
    border-radius: 28px;
    background-color: #fff
}

.intro-card.intro-grid-cell--animation {
    display: -ms-grid;
    display: grid;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    grid-auto-columns: 1fr;
    grid-column-gap: 16px;
    grid-row-gap: 16px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows:auto;
    border-style: none;
    background-color: #1d1d1f;
    background-image: linear-gradient(120deg, #eb4f56, #9d66e9);
    color: #fff
}

.intro-card.intro-grid-cell--flex {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column
}

.intro-card.intro-card--performance {
    overflow: hidden
}

.intro-card.intro-card--integrations {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    overflow: hidden;
    padding: 0 0 97px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-transform: translate(0, 0);
    -ms-transform: translate(0, 0);
    transform: translate(0, 0);
    text-align: center
}

.intro-card.intro-card--pixel {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-right: 24px;
    padding-left: 24px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-style: none;
    background-color: #1d1d1f;
    background-image: url("https://assets.website-files.com/605739cf94ca6b3bfeae0833/6137431ff45a39b264299e9d_pixel-pattern.png"), linear-gradient(45deg, #16327c, #1ebf98);
    background-position: 50% 0, 0 0;
    background-size: cover, auto;
    background-repeat: no-repeat, repeat;
    color: #f5f5f7
}

.intro-card.intro-card--responsive {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-top: 58px;
    padding-bottom: 0;
    -webkit-box-align: start;
    -webkit-align-items: flex-start;
    -ms-flex-align: start;
    align-items: flex-start
}

.intro-card.intro-card--tiny {
    padding-right: 52px;
    padding-left: 52px
}

.intro-card.intro-card--tiny.cc-dev-speed {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.intro-card.intro-card--tiny.cc-code {
    padding-right: 32px
}

.intro-card.intro-card--scale {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 0;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
    -ms-flex-pack: end;
    justify-content: flex-end
}

.intro-card.intro-card--stats-cell {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.fluid-gradient-heading {
    margin-top: 0;
    padding-top: 8px;
    padding-bottom: 8px;
    font-size: 92px;
    line-height: 1.1;
    letter-spacing: -.045em;
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.fluid-gradient-heading.fluid-gradient-heading--footer {
    margin-bottom: 24px
}

.fluid-gradient-heading.fluid-gradient-heading--hero {
    margin-right: auto;
    margin-left: auto;
    line-height: 1.05;
    text-align: center
}

.fluid-gradient-heading.fluid-gradient-heading--hero.cc-de {
    font-size: 87px
}

.fluid-gradient-heading.fluid-gradient-heading--hero.cc-en {
    font-size: 103px
}

.intro-card-heading {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 32px;
    line-height: 1.2;
    font-weight: 600;
    letter-spacing: -.025em
}

.intro-card-heading.intro-cell-heading--big {
    font-size: 44px
}

.intro-card-heading.intro-cell-heading--center {
    text-align: center
}

.intro-card-heading.intro-card-heading--tiny {
    font-size: 18px
}

.intro-card-heading.intro-card-heading--tiny.cc-dev-speed {
    position: absolute;
    left: 52px;
    top: 64px;
    right: auto;
    bottom: auto
}

.intro-card-heading.intro-card-heading--scale {
    position: absolute;
    left: 64px;
    top: 64px;
    right: auto;
    bottom: auto;
    max-width: 350px
}

.stats-grid {
    grid-column-gap: 18px;
    grid-row-gap: 18px
}

.intro-stats-figure {
    display: inline;
    margin-left: -2px;
    font-size: 64px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: -.05em
}

.stats-figure-wrap {
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.stats-figure-wrap.stats-figure-wrap--kunden {
    background-image: linear-gradient(225deg, #5af141, #2a9dce)
}

.stats-figure-wrap.stats-figure-wrap--jahre {
    background-image: linear-gradient(135deg, #63e3dc, #1254c2)
}

.stats-figure-wrap.stats-figure-wrap--projekte {
    background-image: linear-gradient(225deg, #e374ff, #3d30ea)
}

.stats-figure-wrap.stats-figure-wrap--leidenschaft {
    background-image: linear-gradient(135deg, #ffb077, #b82698)
}

.stats-descriptoin {
    margin-top: 4px;
    opacity: .75;
    font-weight: 500;
    letter-spacing: -.025em
}

.intro-stats-symbol {
    display: inline;
    padding-left: 3px;
    font-size: 24px;
    font-weight: 600;
    letter-spacing: -.025em
}

.intro-stats-symbol.intro-stats-symbol--plus {
    font-size: 32px;
    line-height: .6
}

.intro-stats-symbol.intro-stats-symbol--jahre, .intro-stats-symbol.intro-stats-symbol--prozent {
    -webkit-align-self: flex-end;
    -ms-flex-item-align: end;
    align-self: flex-end
}

.cta-link-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 11px;
    height: 10px;
    margin-left: 2px;
    padding-top: 2.5px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.cta-link-icon.cta-link-icon--hero {
    width: 13px;
    height: 12px;
    margin-top: 3.5px;
    margin-left: 4px;
    padding-top: 0
}

.settings-top {
    margin-bottom: 22px;
    padding-top: 24px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    letter-spacing: -.02em
}

.settings-section-title {
    margin-bottom: 10px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.015em
}

.settings-close {
    position: absolute;
    left: 18px;
    top: 18px;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 34px;
    height: 34px;
    padding: 10px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: hsla(0, 0%, 60.7%, .5)
}

.settings-close-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 12px;
    height: 12px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.settings-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 20px;
    height: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.clients-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 8px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: space-around;
    -ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -ms-flex: 1;
    flex: 1
}

.client-logo {
    max-height: 44px;
    max-width: 160px;
    margin-top: 56px
}

.projects-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.load-more-button {
    margin-top: 48px;
    padding: 8px 22px;
    font-size: 20px;
    font-weight: 500;
    letter-spacing: -.02em;
    text-decoration: none
}

.section-heading-eyebrow {
    margin-top: 0;
    margin-bottom: 16px;
    padding-left: .1em;
    opacity: .75;
    font-size: 28px;
    line-height: 1.2;
    letter-spacing: -.04em
}

.workflow-description {
    opacity: .75;
    font-size: 17px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -.02em
}

.projects-list-img {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    width: 100%;
    height: 100%;
    max-width: none;
    -o-object-fit: cover;
    object-fit: cover
}

.form-intro-img-wrap {
    position: relative;
    margin-bottom: 42px
}

.form-intro-text {
    opacity: .7;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -.015em
}

.anfrage-nav-button {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 12px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.anfrage-back-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 7px;
    margin-right: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.hidden {
    display: none
}

.anfrage-button {
    padding: 12px 90px;
    border-radius: 12px;
    background-color: #006ddb;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    letter-spacing: -.015em;
    text-decoration: none
}

.anfrage-button:hover {
    background-image: -webkit-gradient(linear, left top, left bottom, from(hsla(0, 0%, 100%, .1)), to(hsla(0, 0%, 100%, .1)));
    background-image: linear-gradient(180deg, hsla(0, 0%, 100%, .1), hsla(0, 0%, 100%, .1))
}

.anfrage-button:active {
    background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, .15)), to(rgba(0, 0, 0, .15)));
    background-image: linear-gradient(180deg, rgba(0, 0, 0, .15), rgba(0, 0, 0, .15))
}

.divider {
    width: 100%;
    height: 1px;
    background-color: hsla(0, 0%, 53.3%, .2)
}

.divider.divider--form-top {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 0
}

.form-slide-top {
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: 15;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 64px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background-color: #fff
}

.form-progress {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    height: 4px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between
}

.form-progress-step {
    width: 100%;
    height: 100%;
    margin-right: 3px;
    margin-left: 3px;
    border-radius: 20px;
    background-color: hsla(0, 0%, 53.3%, .4)
}

.form-progress-step.form-progress-step--filled {
    background-color: #006ddb
}

.form-slide-content {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 32px
}

.form-field-label--optional {
    margin-left: 4px;
    opacity: .7;
    font-size: 12px;
    font-weight: 500
}

.checkbox-label {
    cursor: none
}

.form-success-subheading {
    margin-top: 10px;
    margin-bottom: 24px;
    font-size: 18px;
    line-height: 1.4;
    font-weight: 500;
    letter-spacing: -.025em
}

.form-success-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.hero-scroll-wrap {
    position: relative
}

.hero-heading-ui-wrap {
    position: relative;
    margin-top: 18px;
    margin-bottom: 22px
}

.hero-heading-ui {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: -1;
    border: 1px solid #168bf4;
    opacity: 0
}

.hero-heading-ui-tab {
    position: absolute;
    left: -1px;
    top: -20px;
    right: auto;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 20px;
    padding: 3px 12px 2px 8px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    background-color: #168bf4;
    color: #fff;
    font-size: 11px
}

.hero-ui {
    position: relative;
    left: 50%;
    z-index: 1;
    width: 16.9em;
    max-width: none;
    margin-top: 3.2em;
    margin-left: -8.45em;
    opacity: 0;
    font-size: 10vw
}

.overflow-wrap {
    overflow: hidden;
    height: 100%
}

.sticky-gap {
    height: 100vh
}

.hero-scroll-trigger {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: auto;
    z-index: -1;
    height: 100vh
}

.hero-heading-ui-tab-h1 {
    margin-right: 4px
}

.hero-paragraph-mobile {
    display: none;
    font-size: 24px;
    line-height: 1.25;
    font-weight: 500;
    letter-spacing: -.03em
}

.faq-wrap {
    display: -ms-grid;
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-rows: auto;
    grid-column-gap: 18px;
    grid-row-gap: 18px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns:1fr 1fr;
    -ms-grid-rows: auto auto;
    grid-template-rows:auto auto
}

.faq-item {
    position: relative;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 120px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 24px;
    -webkit-perspective: 1600px;
    perspective: 1600px;
    -webkit-transform: perspective(0);
    transform: perspective(0)
}

.faq-front {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 120px;
    padding: 36px 64px 36px 32px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 24px;
    background-color: #fff;
    box-shadow: inset 0 0 0 1px hsla(0, 0%, 51.4%, .16);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: box-shadow .1s linear;
    transition: box-shadow .1s linear
}

.faq-front:hover {
    box-shadow: inset 0 0 0 2px #006ddb
}

.faq-back {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 120px;
    padding: 18px 64px 18px 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    border-radius: 24px;
    background-color: #fff;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotateX(-180deg) rotateY(0) rotateZ(0);
    transform: rotateX(-180deg) rotateY(0) rotateZ(0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.faq-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 11;
    display: none;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, .8);
    opacity: 0
}

.faq-title {
    max-width: 445px;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 18px;
    line-height: 1.3;
    font-weight: 600;
    letter-spacing: -.02em
}

.faq-icon-wrap {
    position: absolute;
    left: auto;
    top: auto;
    right: 28px;
    bottom: auto;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 18px;
    height: 18px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.faq-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.faq-icon.faq-icon--close {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg)
}

.faq-answer {
    font-size: 15px;
    line-height: 1.35;
    font-weight: 500;
    letter-spacing: -.015em
}

.faq-answer ul {
    margin-top: 12px;
    margin-bottom: 0;
    padding-left: 18px
}

.faq-answer li {
    padding-top: 2px;
    padding-bottom: 2px
}

.language-notice-button-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.projects-list-item-wrap {
    position: relative;
    z-index: 2;
    width: 100%
}

.projects-list-item-wrap.fsm {
    overflow: auto;
    border-radius: 22px;
    -webkit-transition: .5s;
    transition: .5s
}

.project-overlay {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 505;
    display: none;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .8);
    opacity: 0
}

.project-list-item-container {
    position: relative;
    z-index: 5;
    overflow: hidden;
    max-width: 980px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 22px;
    background-color: #f7f7f7;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: scale3d(1 none, 1 none, 1 none) translate(0, 0);
    transform: scale3d(1 none, 1 none, 1 none) translate(0, 0);
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d
}

.project-item-info-wrap {
    display: none
}

.project-title {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 64px;
    line-height: 1.2;
    letter-spacing: -.045em
}

.project-info-header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    max-width: 560px;
    margin-right: auto;
    margin-bottom: 56px;
    margin-left: auto;
    padding-top: 64px;
    padding-right: 18px;
    padding-left: 18px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    text-align: center
}

.project-item-close {
    position: absolute;
    left: auto;
    top: 16px;
    right: 16px;
    bottom: auto;
    z-index: 3;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 32px;
    height: 32px;
    padding: 0;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 50%;
    background-color: rgba(29, 29, 31, .8);
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, .12);
    -webkit-transition: -webkit-transform .5s;
    transition: transform .5s;
    transition: transform .5s, -webkit-transform .5s;
    color: #afafb5
}

.project-item-close-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.project-slider-img {
    display: block
}

.swiper-container {
    overflow: hidden;
    width: 100%;
    border-top: 1px solid transparent;
    background-color: #fff
}

.swiper-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    padding-top: 64px;
    padding-bottom: 64px
}

.project-services-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 12px;
    padding: 6px 16px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 10px;
    background-color: hsla(0, 0%, 46%, .12)
}

.project-service-label {
    opacity: .65;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: -.025em
}

.footer-legal {
    opacity: .7
}

.intro-card-performance-img {
    width: 120%;
    max-width: 600px;
    margin-top: 36px;
    margin-bottom: -26px;
    margin-left: -64px;
    -webkit-transform-origin: 100% 0%;
    -ms-transform-origin: 100% 0%;
    transform-origin: 100% 0%;
    -o-object-fit: contain;
    object-fit: contain;
    -o-object-position: 0% 50%;
    object-position: 0% 50%
}

.intro-cad-responsive-img {
    min-height: 540px;
    margin-left: -136px;
    -o-object-fit: contain;
    object-fit: contain
}

.intro-card-code-wrap {
    max-width: 540px;
    margin-top: 52px;
    margin-right: auto;
    margin-left: auto;
    font-family: 'Ibm plex mono', sans-serif;
    font-size: 24px;
    line-height: 2;
    font-weight: 500
}

.intro-card-code-indented {
    margin-left: 28px
}

.code-color {
    color: #e62615
}

.intro-card-scale-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -webkit-box-align: end;
    -webkit-align-items: flex-end;
    -ms-flex-align: end;
    align-items: flex-end
}

.intro-card-scale-bar {
    width: 10%;
    max-width: 54px;
    border-radius: 10px 10px 0 0;
    background-image: -webkit-gradient(linear, left top, left bottom, from(#5b9fcf), to(#1f338e));
    background-image: linear-gradient(180deg, #5b9fcf, #1f338e)
}

.intro-card-scale-bar.intro-card-scale-bar--1 {
    height: 20%
}

.intro-card-scale-bar.intro-card-scale-bar--2 {
    height: 30%
}

.intro-card-scale-bar.intro-card-scale-bar--3 {
    height: 45%
}

.intro-card-scale-bar.intro-card-scale-bar--4 {
    height: 67.5%
}

.intro-card-scale-bar.intro-card-scale-bar--5 {
    height: 100%
}

.intro-card-dev-speed-wrap {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 32px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    font-size: 80px;
    font-weight: 600;
    letter-spacing: -.045em
}

.intro-card-dev-speed-months {
    position: relative
}

.dev-speed-month-line {
    position: absolute;
    top: 50%;
    width: 110%;
    height: 10px;
    margin-left: -5%;
    border-radius: 20px;
    background-color: #1d1d1f
}

.dev-speed-weeks {
    background-image: linear-gradient(-70deg, #ed4e50, #9867f0);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent
}

.project-info-cta-wrap {
    position: relative;
    z-index: 2;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-bottom: 56px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center
}

.faq-top-line {
    width: 90%;
    height: 1px;
    max-width: 980px;
    margin-right: auto;
    margin-bottom: 140px;
    margin-left: auto;
    background-image: -webkit-gradient(linear, left top, right top, from(hsla(0, 0%, 53.3%, 0)), color-stop(20%, hsla(0, 0%, 53.3%, .3)), color-stop(80%, hsla(0, 0%, 53.3%, .3)), to(hsla(0, 0%, 53.3%, 0)));
    background-image: linear-gradient(90deg, hsla(0, 0%, 53.3%, 0), hsla(0, 0%, 53.3%, .3) 20%, hsla(0, 0%, 53.3%, .3) 80%, hsla(0, 0%, 53.3%, 0))
}

.custom-code {
    display: none
}

.project-list-img-wrap {
    position: relative;
    width: 100%;
    padding-top: 52.04%
}

.project-item-vid {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    width: 100%;
    height: 100%;
    opacity: 0;
    -webkit-transition: opacity .2s;
    transition: opacity .2s
}

.project-cta {
    padding: 10px 28px;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 12px;
    background-color: #006ddb;
    -webkit-transition: background-color .2s;
    transition: background-color .2s;
    color: #fff;
    font-weight: 500;
    text-decoration: none
}

.project-cta:hover {
    background-color: #0079f2
}

.project-services-dot {
    width: 4px;
    height: 4px;
    min-width: 4px;
    margin-right: 8px;
    margin-left: 8px;
    border-radius: 50%;
    opacity: .65
}

.anfrage-intro-bottom {
    position: absolute;
    left: 0;
    top: auto;
    right: 0;
    bottom: 16px;
    padding-right: 18px;
    padding-left: 18px;
    opacity: .5;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    letter-spacing: -.015em
}

.intro-card-pixel-perfect {
    letter-spacing: -.014em
}

@media screen and (max-width: 991px) {
    a {
        cursor: auto
    }

    .container.container--hero {
        position: static;
        height: auto;
        min-height: 100vh;
        padding-top: 114px;
        padding-bottom: 80px;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around
    }

    .nav__logo {
        margin-left: 0
    }

    .cta-link.cta-link--hero {
        font-size: 20px
    }

    .cta-link.cta__link--nav {
        margin-right: 0;
        padding-right: 0;
        padding-left: 0
    }

    .hero {
        height: auto
    }

    .hero-heading {
        font-size: 72px
    }

    .section {
        padding-top: 80px;
        padding-bottom: 80px
    }

    .section.section--manifest {
        display: none
    }

    .section-heading {
        max-width: 650px;
        font-size: 72px
    }

    .section-heading.section-heading--faq {
        font-size: 56px
    }

    .intro-card-description {
        margin-top: 18px;
        line-height: 1.4
    }

    .anim-video-wrap {
        max-width: none
    }

    .sticky-wrap.sticky-wrap--hero {
        position: static;
        height: auto;
        min-height: 0
    }

    .projects-list-item-link:hover {
        margin-top: 0;
        padding-bottom: 0;
        box-shadow: none
    }

    .projects-list-item-link.lift {
        border-radius: 16px
    }

    .projects-list {
        grid-column-gap: 24px;
        grid-row-gap: 24px
    }

    .section-header {
        margin-bottom: 80px
    }

    .workflow-card {
        max-width: none;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .nav__logo__link {
        padding-right: 0;
        padding-left: 0
    }

    .settings-wrap {
        bottom: 12px
    }

    .settings__inner {
        border-radius: 10px
    }

    .form-heading {
        font-size: 34px
    }

    .form-checkbox-field {
        cursor: auto
    }

    .form-checkbox-field:hover {
        -webkit-transform: none;
        -ms-transform: none;
        transform: none
    }

    .anfrage__datenschutz-check, .anfrage__datenschutz-checkbox, .form-field {
        cursor: auto
    }

    .legal-text h1 {
        margin-bottom: 48px;
        font-size: 48px
    }

    .legal-text h2 {
        margin-bottom: 12px;
        font-size: 24px
    }

    .hero-img-wrap {
        width: 45vh;
        height: 45vh;
        min-height: 0;
        min-width: 0
    }

    .settings-handle:hover {
        background-image: none
    }

    /*.cursor {*/
    /*    display: none*/
    /*}*/

    .hero-eyebrow {
        font-size: 24px;
        font-weight: 500
    }

    .intro-grid, .intro-grid.intro-grid--variant {
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .intro-card.intro-grid-cell--animation {
        grid-row-gap: 32px;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .intro-card.intro-card--integrations {
        padding-bottom: 64px
    }

    .intro-card.intro-card--pixel {
        background-position: 50% 100%, 0 0;
        background-size: cover, auto
    }

    .intro-card.intro-card--tiny.cc-dev-speed {
        padding-top: 110px;
        padding-bottom: 80px
    }

    .intro-card.intro-card--scale {
        height: 450px
    }

    .intro-card.intro-card--stats-cell {
        padding-top: 48px;
        padding-bottom: 48px
    }

    .fluid-gradient-heading.fluid-gradient-heading--footer {
        font-size: 64px
    }

    .intro-card-heading.intro-cell-heading--big {
        font-size: 40px
    }

    .section-heading-eyebrow {
        font-size: 26px
    }

    .anfrage-nav-button {
        cursor: pointer
    }

    .hero-heading-ui-wrap {
        margin-bottom: 18px
    }

    .hero-heading-ui, .hero-scroll-trigger, .hero-ui, .sticky-gap {
        display: none
    }

    .hero-paragraph-mobile {
        display: block;
        max-width: 460px;
        margin-bottom: 10px
    }

    .projects-list-item-wrap.fsm {
        border-radius: 16px
    }

    .project-list-item-container {
        border-radius: 16px;
        -webkit-transition: border-radius .5s;
        transition: border-radius .5s
    }

    .project-title {
        font-size: 56px
    }

    .intro-card-integrations-img {
        margin-bottom: 32px
    }

    .faq-top-line {
        margin-bottom: 80px
    }
}

@media screen and (max-width: 767px) {
    .container.container--nav {
        height: 24px
    }

    .container.container--hero {
        padding-top: 80px;
        padding-bottom: 64px
    }

    .nav__menu {
        display: none
    }

    .nav__logo {
        font-size: 18px
    }

    .cta-link {
        font-size: 16px
    }

    .cta-link.cta-link--form {
        font-size: 18px
    }

    .hero-heading {
        font-size: 64px
    }

    .section-heading {
        font-size: 56px
    }

    .section-heading.section-heading--faq {
        font-size: 48px
    }

    .intro-card-description {
        font-size: 18px
    }

    .intro-card-description.intro-card-description--animations {
        margin-top: 12px
    }

    .projects-list-wrapper {
        margin-top: -48px
    }

    .projects-list-item {
        width: 100%;
        margin-top: 24px;
        margin-bottom: 0
    }

    .projects-list-item-link.lift {
        border-radius: 18px
    }

    .projects-list {
        display: block
    }

    .workflow-card {
        height: 340px;
        padding: 52px 48px
    }

    .workflow-card__heading {
        font-size: 28px
    }

    .nav__logo__link {
        padding-right: 0;
        padding-left: 0
    }

    .workflow-cards-wrap {
        padding-top: 38px
    }

    .anfrage-wrap {
        max-height: none
    }

    .anfrage-bottom {
        padding-top: 16px;
        padding-bottom: 16px
    }

    .form-heading {
        font-size: 28px
    }

    .form-intro-img {
        width: 120px;
        height: 120px
    }

    .anfrage-form-block {
        padding-bottom: 56px
    }

    .form-eyebrow {
        font-size: 14px
    }

    .form-checkbox-wrap {
        margin-top: 32px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .anfrage-start-button {
        margin-top: 32px
    }

    .form-field-label {
        font-size: 14px
    }

    .footer-menu-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: reverse;
        -webkit-flex-direction: column-reverse;
        -ms-flex-direction: column-reverse;
        flex-direction: column-reverse;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .footer__link-wrap {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        margin-bottom: 18px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-around;
        -ms-flex-pack: distribute;
        justify-content: space-around;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center
    }

    .footer-link {
        margin-bottom: 16px;
        margin-left: 0
    }

    .footer-cta-wrap {
        padding-top: 120px;
        padding-bottom: 120px
    }

    ._404-heading {
        font-size: 42px
    }

    .legal-text h1 {
        margin-bottom: 34px;
        padding-bottom: 18px;
        text-align: left
    }

    .legal-text h2 {
        font-size: 22px
    }

    .language-notice-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center
    }

    .language-notice-title {
        margin-bottom: 24px;
        text-align: center
    }

    .language-notice-cancel {
        margin-top: 12px;
        margin-left: 0
    }

    .hero-img-wrap {
        width: 350px;
        height: 350px
    }

    .hero-eyebrow {
        font-size: 22px
    }

    .intro-card {
        padding: 56px 38px
    }

    .intro-card.intro-card--integrations {
        padding-bottom: 65px
    }

    .intro-card.intro-card--responsive {
        padding-top: 50px;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .fluid-gradient-heading.fluid-gradient-heading--footer {
        font-size: 54px
    }

    .intro-card-heading, .intro-card-heading.intro-cell-heading--big {
        font-size: 32px
    }

    .intro-stats-figure {
        font-size: 56px
    }

    .intro-stats-symbol.intro-stats-symbol--plus {
        top: -9px;
        font-size: 26px
    }

    .intro-stats-symbol.intro-stats-symbol--jahre {
        bottom: 3px;
        font-size: 18px
    }

    .load-more-button {
        font-size: 18px
    }

    .section-heading-eyebrow {
        padding-left: .1em;
        font-size: 24px
    }

    .form-progress {
        width: 140px
    }

    .form-progress-step {
        margin-right: 2px;
        margin-left: 2px
    }

    .hero-paragraph-mobile {
        font-size: 22px
    }

    .faq-wrap {
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .language-notice-button-wrap {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column
    }

    .intro-card-performance-img {
        width: 110%
    }

    .intro-cad-responsive-img {
        min-height: 0;
        margin-top: 20px;
        margin-left: 0
    }

    .intro-card-code-wrap {
        font-size: 2.8vw
    }
}

@media screen and (max-width: 479px) {
    .container.container--hero {
        padding-top: 80px
    }

    .container.container--large {
        width: 90%;
        padding-right: 0;
        padding-left: 0
    }

    .cta-link.cta-link--hero {
        font-size: 18px
    }

    .hero {
        overflow: hidden
    }

    .hero-heading {
        font-size: 54px
    }

    .hero-img-container {
        padding: 8px
    }

    .section-heading {
        font-size: 52px
    }

    .section-heading.section-heading--faq {
        font-size: 44px
    }

    .intro-card-description {
        margin-top: 14px
    }

    .anim-video-wrap {
        padding-bottom: 0
    }

    .projects-list-item {
        margin-top: 5vw
    }

    .workflow-card {
        height: 380px;
        padding-right: 10%;
        padding-left: 10%
    }

    .workflow-card-step {
        margin-bottom: 6px
    }

    .workflow-card__heading {
        margin-bottom: 8px
    }

    .workflow-card__launch-konfetti {
        left: 50%;
        top: 0;
        right: 0;
        bottom: auto;
        width: 180%;
        -webkit-transform: translate(-50%, -20%);
        -ms-transform: translate(-50%, -20%);
        transform: translate(-50%, -20%)
    }

    .settings-wrap {
        left: auto;
        right: 12px
    }

    .anfrage-wrap {
        width: 100%;
        height: 100%;
        border-radius: 0
    }

    .anfrage-bottom {
        padding: 24px 18px
    }

    .form-header {
        max-width: 250px
    }

    .form-checkbox-wrap {
        margin-top: 28px
    }

    .form-checkbox-field {
        min-height: 56px;
        padding-left: 22px;
        border-radius: 12px
    }

    .form-checkbox-title {
        font-size: 16px
    }

    .form-checkbox, .form-checkbox.w--redirected-checked {
        border-radius: 12px
    }

    .anfrage-intro {
        border-radius: 0
    }

    .anfrage-nav-button-wrap {
        top: 12px;
        right: 6px
    }

    .anfrage-nav-button-wrap:hover {
        border-radius: 8px
    }

    .anfrage-nav-button-wrap.anfrage-nav-button-wrap--back {
        left: 6px;
        top: 12px
    }

    .form-content {
        padding-top: 18px
    }

    .form-field {
        padding-right: 16px;
        padding-left: 16px;
        border-radius: 12px
    }

    .anfrage__datenschutz-check {
        padding-left: 0;
        -webkit-box-align: start;
        -webkit-align-items: flex-start;
        -ms-flex-align: start;
        align-items: flex-start
    }

    .anfrage__datenschutz-checkbox {
        margin-bottom: 8px;
        margin-left: 0
    }

    ._404-heading {
        font-size: 32px
    }

    .legal-text h1 {
        font-size: 38px
    }

    .legal-text h2 {
        margin-top: 34px
    }

    .language-notice-wrap {
        left: 0;
        top: auto;
        right: 0;
        bottom: 0;
        padding-right: 32px;
        padding-bottom: 20px;
        padding-left: 32px;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0
    }

    .language-notice-title {
        max-width: 300px;
        margin-bottom: 32px;
        font-size: 22px
    }

    .hero-img-wrap {
        width: 90%;
        height: 90%;
        margin-bottom: 42px
    }

    .hero-img-shadow-wrap {
        -webkit-filter: blur(15px);
        filter: blur(15px)
    }

    .settings-handle {
        -webkit-box-pack: end;
        -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
        justify-content: flex-end
    }

    .hero-eyebrow {
        font-size: 20px
    }

    .intro-grid {
        margin-bottom: 5vw;
        grid-row-gap: 5vw;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .intro-card {
        padding: 38px 28px;
        border-radius: 24px
    }

    .intro-card.intro-grid-cell--animation {
        padding-bottom: 20px;
        -ms-grid-columns: 1fr;
        grid-template-columns:1fr
    }

    .intro-card.intro-card--integrations {
        padding-bottom: 38px
    }

    .intro-card.intro-card--pixel {
        padding-top: 71px;
        padding-bottom: 71px
    }

    .intro-card.intro-card--responsive {
        padding-top: 32px;
        padding-bottom: 18px
    }

    .intro-card.intro-card--tiny {
        padding-top: 28px;
        padding-right: 28px;
        padding-left: 28px
    }

    .intro-card.intro-card--tiny.cc-dev-speed {
        padding-top: 63px;
        padding-bottom: 48px
    }

    .intro-card.intro-card--tiny.cc-code {
        padding-right: 5px
    }

    .intro-card.intro-card--scale {
        height: 280px;
        padding-top: 28px
    }

    .fluid-gradient-heading {
        font-size: 34px
    }

    .fluid-gradient-heading.fluid-gradient-heading--footer {
        margin-bottom: 10px;
        font-size: 10vw
    }

    .intro-card-heading, .intro-card-heading.intro-cell-heading--big {
        font-size: 28px
    }

    .intro-card-heading.intro-card-heading--tiny {
        font-size: 16px
    }

    .intro-card-heading.intro-card-heading--tiny.cc-dev-speed {
        left: 28px;
        top: 32px
    }

    .intro-card-heading.intro-card-heading--scale {
        left: 28px;
        top: 28px;
        max-width: 180px;
        font-size: 22px
    }

    .stats-grid {
        grid-column-gap: 12px;
        grid-row-gap: 12px
    }

    .intro-stats-figure {
        font-size: 48px
    }

    .stats-descriptoin {
        margin-top: 2px;
        font-size: 14px
    }

    .intro-stats-symbol {
        font-size: 20px
    }

    .intro-stats-symbol.intro-stats-symbol--plus {
        line-height: .5
    }

    .settings-close {
        left: auto;
        top: 18px;
        right: 18px;
        bottom: auto
    }

    .client-logo {
        max-height: 33px;
        max-width: 120px;
        margin-top: 48px
    }

    .section-heading-eyebrow {
        margin-bottom: 12px;
        font-size: 22px
    }

    .workflow-description {
        font-size: 15px;
        line-height: 1.3
    }

    .anfrage-nav-button {
        font-size: 14px
    }

    .anfrage-button {
        width: 100%;
        text-align: center
    }

    .form-slide-top {
        height: 56px
    }

    .form-progress {
        width: 124px
    }

    .form-progress-step {
        margin-right: 1px;
        margin-left: 1px
    }

    .form-slide-content {
        padding-right: 18px;
        padding-left: 18px
    }

    .hero-heading-ui-wrap {
        margin-bottom: 18px
    }

    .hero-paragraph-mobile {
        font-size: 20px
    }

    .faq-back {
        height: 160px;
        -webkit-transform: rotateX(-180deg) rotateY(0) rotateZ(0);
        transform: rotateX(-180deg) rotateY(0) rotateZ(0)
    }

    .faq-title {
        font-size: 16px
    }

    .project-title {
        font-size: 48px
    }

    .project-info-header {
        margin-bottom: 32px;
        padding-top: 48px
    }

    .project-services-wrap {
        padding: 5px 12px
    }

    .project-service-label {
        font-size: 12px
    }

    .intro-card-performance-img {
        margin-left: -28px
    }

    .intro-cad-responsive-img {
        max-width: 100%;
        margin-top: 28px
    }

    .intro-card-code-wrap {
        margin-top: 30px;
        margin-right: -12px;
        font-size: 3.3vw
    }

    .intro-card-code-indented {
        margin-left: 3vw
    }

    .intro-card-scale-bar {
        border-top-left-radius: 6px;
        border-top-right-radius: 6px
    }

    .intro-card-dev-speed-wrap {
        font-size: 56px
    }

    .dev-speed-month-line {
        height: 8px
    }

    .project-info-cta-wrap {
        padding-bottom: 44px
    }
}

#w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58, #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-28f29210 {
    -ms-grid-row: span 1;
    grid-row-start: span 1;
    -ms-grid-row-span: 1;
    grid-row-end: span 1;
    -ms-grid-column: span 2;
    grid-column-start: span 2;
    -ms-grid-column-span: 2;
    grid-column-end: span 2
}

#w-node-_4ba720d1-a98d-161a-92f5-36e6e21015f4-06f6bd58, #w-node-_4ba720d1-a98d-161a-92f5-36e6e21015f4-28f29210 {
    -ms-grid-column-align: center;
    justify-self: center
}

#w-node-_671cd3a2-7408-189b-b9c0-8ea917a5ec73-06f6bd58, #w-node-_671cd3a2-7408-189b-b9c0-8ea917a5ec73-28f29210 {
    -webkit-align-self: stretch;
    -ms-flex-item-align: stretch;
    -ms-grid-row-align: stretch;
    align-self: stretch;
    -ms-grid-column-align: stretch;
    justify-self: stretch
}

@media screen and (max-width: 991px) {
    #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58, #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-28f29210 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1
    }
}

@media screen and (max-width: 479px) {
    #w-node-_10a1a4da-5d2d-cb83-41b6-3638d47e2759-06f6bd58, #w-node-_10a1a4da-5d2d-cb83-41b6-3638d47e2759-28f29210, #w-node-_2978f1ac-3efb-fe90-7db0-d31217849e8e-06f6bd58, #w-node-_2978f1ac-3efb-fe90-7db0-d31217849e8e-28f29210, #w-node-_2978f1ac-3efb-fe90-7db0-d31217849e92-06f6bd58, #w-node-_2978f1ac-3efb-fe90-7db0-d31217849e92-28f29210, #w-node-_621f5c15-aa3a-ea36-1d24-9d852355afac-06f6bd58, #w-node-_621f5c15-aa3a-ea36-1d24-9d852355afac-28f29210, #w-node-_7343152d-f711-deb4-0118-197cfa067569-06f6bd58, #w-node-_7343152d-f711-deb4-0118-197cfa067569-28f29210, #w-node-_76134843-f7b6-aac7-450b-aea7336dc74c-06f6bd58, #w-node-_76134843-f7b6-aac7-450b-aea7336dc74c-28f29210, #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-06f6bd58, #w-node-_76d47fde-5b75-4a0b-8eae-ac850b5e4a5c-28f29210, #w-node-_84b8e937-c045-408b-d680-33095298155a-06f6bd58, #w-node-_84b8e937-c045-408b-d680-33095298155a-28f29210, #w-node-_9d0fa4dc-f9bb-4008-1cb5-da9f049a0761-06f6bd58, #w-node-_9d0fa4dc-f9bb-4008-1cb5-da9f049a0761-28f29210, #w-node-cd10a809-fa1b-95ba-53a0-cdc422824f04-06f6bd58, #w-node-cd10a809-fa1b-95ba-53a0-cdc422824f04-28f29210, #w-node-f351f543-f0f7-460b-07e1-fd5780deec32-06f6bd58, #w-node-f351f543-f0f7-460b-07e1-fd5780deec32-28f29210 {
        -ms-grid-row: span 1;
        grid-row-start: span 1;
        -ms-grid-row-span: 1;
        grid-row-end: span 1;
        -ms-grid-column: span 1;
        grid-column-start: span 1;
        -ms-grid-column-span: 1;
        grid-column-end: span 1
    }
}


