.form-slide-content {
    max-width: 550px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 32px;
    padding-right: 32px;
    padding-left: 32px
}

.hidden {
    display: none;
}

@media screen and (max-width: 479px) {
    .form-slide-content {
        padding-right: 18px;
        padding-left: 18px
    }
}