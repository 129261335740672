.w-layout-grid {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    grid-row-gap: 16px;
    grid-column-gap: 16px;
    background: radial-gradient(ellipse 100% 40% at 50% 60%,rgba(102,99,246,0.07),var(--transparent));
}

.intro-grid {
    margin-bottom: 28px;
    grid-column-gap: 28px;
    grid-row-gap: 28px;
}

.intro-grid.intro-grid--variant {
    grid-template-columns: 0.4fr 1fr;
    grid-template-rows: auto;
}

@media screen and (max-width: 991px) {
    .intro-grid, .intro-grid.intro-grid--variant {
        -ms-grid-columns: 1fr;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 767px) {
    .intro-grid {
        margin-bottom: 5vw;
        grid-row-gap: 5vw;
        grid-template-columns: 1fr;
    }
}

@media screen and (max-width: 479px) {

}