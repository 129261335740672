.hMziHQ {
    color: currentcolor;
    text-align: center;
    font-size: 26px;
    line-height: 1.2;
    letter-spacing: -0.01em;
    font-weight: 450;
}

.DlNrA {
    color: rgb(180, 188, 208);
    text-align: center;
    margin: 16px 0px 0px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: 450;
}

::selection {
    color: rgb(208, 214, 224);
    background: rgb(92, 103, 199);
}

svg {
    flex-shrink: 0;
}

.js-focus-visible :focus:not(.focus-visible) {
    outline: none;
}

.byYCNF {
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: end;
    justify-content: flex-end;
    overflow: hidden;
    min-height: 480px;
    border-radius: 28px;
    padding: 56px;
    position: relative;
    background: linear-gradient(rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.05) 100%);
}



.byYCNF::before {
    content: "";
    pointer-events: none;
    user-select: none;
    position: absolute;
    inset: 0px;
    border-radius: inherit;
    padding: 1px;
    background: rgba(0, 0, 0, 0) linear-gradient(rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0.07)) repeat scroll 0% 0%;
    mask-mode: match-source, match-source;
    mask-repeat: repeat, repeat;
    mask-clip: content-box, border-box;
    mask-origin: content-box, border-box;
    mask-position: 0% 0%, 0% 0%;
    mask-size: auto, auto;
    mask-image: linear-gradient(black, black), linear-gradient(black, black);
    mask-composite: exclude;
}

@media (max-width: 768px) {
    .byYCNF {
        order: var(--order, 3);
        min-width: 100%;
        min-height: 420px;
        padding: 32px 8px;
        scroll-snap-align: center;
    }
}

.fsBuSa {
    max-width: 420px;
}

.iqNiNA {
    pointer-events: none;
    user-select: none;
}

.cnUOVz.cnUOVz {
    grid-column: auto / span 2;
    --order: 1;
}

.cnUOVz .sc-a238f29c-23 {
    position: absolute;
    top: -92px;
    -webkit-mask-image: linear-gradient(black, transparent 70%);
}

.cnUOVz .sc-a238f29c-23 svg g:last-of-type path {
    fill: transparent;
    animation-duration: 2250ms;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    animation-direction: normal;
    animation-fill-mode: none;
    animation-play-state: running;
    animation-name: hXGgEF;
    animation-delay: calc(var(--index) * 20ms);
}

@media (max-width: 768px) {
    .cnUOVz {
        padding: 32px;
    }

    .cnUOVz .sc-a238f29c-23 svg g:last-of-type path {
        animation: 0s ease 0s 1 normal none running none;
    }
}

@keyframes hXGgEF {
    0%, 9%, 11%, 100% {
        fill: transparent;
    }
    10% {
        fill: rgb(255, 255, 255);
    }
}